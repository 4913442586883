"use client";

import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  Image,
  useColorModeValue,
  Link,
  Checkbox,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../Contexts/DataStore";
import { FieldValues, useForm } from "react-hook-form";
import fullLogoDark from "../img/logos/svg_dark/transparent_dark.svg";
import fullLogoLight from "../img/logos/svg_light/transparent_light.svg";
import { HSeparator } from "../Components/HorizonComponents/separator/Separator";

export default function SignupCard() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const store = useContext(StoreContext);
  const [rememberMe, setRememberMe] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const handleSignUp = async (userData: FieldValues, rememberMe: boolean) => {
    const result = await store.signUp(userData, rememberMe);
    if (result?.status === 400) {
      setError("general", {
        type: "manual",
        message: "Email already in use. Please try again.",
      });
    } else if (result?.status === 200) {
      navigate("/login");
    } else {
      setError("general", {
        type: "manual",
        message: "Something went wrong. Please try again later.",
      });
    }
  };

  const submitBg = useColorModeValue("purple.400", "purple.600");
  const logoImg = useColorModeValue(fullLogoDark, fullLogoLight);
  const inputTextColor = useColorModeValue("gray.800", "gray.200");
  const pageBg = useColorModeValue(
    "linear(to-b, purple.100, gray.100 50%, gray.100)",
    "linear(to-b, purple.900, navy.900 60%, navy.900)"
  );

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bgGradient={pageBg}
      p={4}
    >
      <form onSubmit={handleSubmit((data) => handleSignUp(data, rememberMe))}>
        <Stack
          spacing={4}
          mx={"auto"}
          maxW={"lg"}
          width={{ base: "90vw", md: "70vw", lg: "50vw" }}
        >
          <Stack align={"center"}>
            <Text
              fontSize={{ base: "2xl", md: "4xl" }}
              fontWeight={"bold"}
              mb="0"
            >
              Create an account
            </Text>
          </Stack>
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"xl"}
            p={8}
          >
            <Stack spacing={4}>
              <Box justifyContent="center" display="flex" mb={3}>
                <Image
                  src={logoImg}
                  pointerEvents="none"
                  width={{ base: "400px", md: "375px" }}
                  height={{ base: "50px", md: "60px" }}
                  objectFit="cover"
                />
              </Box>{" "}
              <HStack spacing={4} flexWrap="wrap">
                <Box width={{ base: "100%", md: "48%" }}>
                  <FormControl id="firstName" isRequired>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      placeholder="John"
                      color={inputTextColor}
                      {...register("firstName", { required: true })}
                    />
                    {errors.firstName && (
                      <Text className="text-red-500" mb={0}>
                        First name is required.
                      </Text>
                    )}
                  </FormControl>
                </Box>
                <Box width={{ base: "100%", md: "48%" }}>
                  <FormControl id="lastName" isRequired>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      placeholder="Doe"
                      color={inputTextColor}
                      {...register("lastName", { required: true })}
                    />
                    {errors.lastName && (
                      <Text className="text-red-500" mb={0}>
                        Last name is required.
                      </Text>
                    )}
                  </FormControl>
                </Box>
              </HStack>
              <FormControl id="email" isRequired>
                <FormLabel>Email address</FormLabel>
                <Input
                  color={inputTextColor}
                  placeholder="jdoe@gmail.com"
                  {...register("email", {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                />
                {errors.email && (
                  <Text className="text-red-500" mb={0}>
                    Valid email is required.
                  </Text>
                )}
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    color={inputTextColor}
                    {...register("password", { required: true, minLength: 6 })}
                  />
                  <InputRightElement h={"full"}>
                    <Button
                      variant={"ghost"}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                    >
                      {showPassword ? (
                        <i className="fa-solid fa-eye"></i>
                      ) : (
                        <i className="fa-solid fa-eye-slash"></i>
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {errors.password && (
                  <Text className="text-red-500" mb={0}>
                    A password of minimum length 6 is required.
                  </Text>
                )}
              </FormControl>
              <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Checkbox
                  isChecked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                >
                  Remember me
                </Checkbox>
              </Stack>
              <Stack spacing={10} pt={1}>
                <Button
                  loadingText="Submitting"
                  size="lg"
                  bgColor={submitBg}
                  onClick={() => clearErrors("general")}
                  type="submit"
                  color="white"
                >
                  Sign up
                </Button>
              </Stack>
              {errors.general && typeof errors.general.message === "string" && (
                <Text className="text-red-500 mx-auto" mb={0}>
                  {errors.general.message}
                </Text>
              )}
              <Stack pt={2}>
                <Text align={"center"}>
                  Already a user?{" "}
                  <Link
                    onClick={() => {
                      navigate("/login");
                    }}
                    color={"blue.400"}
                    fontWeight={"bold"}
                  >
                    Login
                  </Link>
                </Text>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </form>
    </Flex>
  );
}
