import { createStandaloneToast } from "@chakra-ui/react";

const { toast } = createStandaloneToast();

export const successToast = (message: string) => {
  toast({
    title: "Success",
    description: message,
    status: "success",
    duration: 2500,
    isClosable: true,
    position: "top-right",
  });
};

export const errorToast = (message: string) => {
  toast({
    title: "Error",
    description: message,
    status: "error",
    duration: 2500,
    isClosable: true,
    position: "top-right",
  });
};

export const infoToast = (message: string) => {
  toast({
    title: "Information",
    description: message,
    status: "info",
    duration: 2500,
    isClosable: true,
    position: "top-right",
  });
};

export const warningToast = (message: string) => {
  toast({
    title: "Warning",
    description: message,
    status: "warning",
    duration: 2500,
    isClosable: true,
    position: "top-right",
  });
};
