import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useColorModeValue,
  FormControl,
  VStack,
  Container,
  Heading,
  Input,
  Flex,
  HStack,
  Checkbox,
  Kbd,
  Box,
  Badge,
  useSteps,
  CheckboxGroup,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  StepTitle,
  StepSeparator,
  useToast,
} from "@chakra-ui/react";
import { HSeparator } from "../HorizonComponents/separator/Separator";
import { StoreContext } from "../../Contexts/DataStore";
import { TaskSchema } from "../../Core/Types";
const materials = ["YouTube Videos", "Websites", "Books", "Coursera Courses"];

const RoadmapEditModal = ({
  task,
  roadmapId,
  size,
  fontSize,
}: {
  task: TaskSchema;
  roadmapId: string;
  size: string;
  fontSize: string;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const newBadgeColor = useColorModeValue("blue.400", "blue.300");
  const textColor = useColorModeValue("white", "black");
  const [edit, setEdit] = useState<string>("");
  const inputColor = useColorModeValue("navy.700", "white");
  const [selectedOptions, setSelectedOptions] = useState<any[]>(materials);

  const [steps, setSteps] = useState([
    { description: "Edit", canProceed: true },
    { description: "Materials", canProceed: false },
  ]);

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const closeAndReset = () => {
    onClose();
    setEdit("");
    setSelectedOptions(materials);
    setActiveStep(0);
  };

  const setEditState = (edit: string) => {
    setEdit(edit);

    const updatedSteps = steps.map((step, index) => {
      if (index === 1 || index === 2) {
        return { ...step, canProceed: edit !== "" };
      }
      return step;
    });
    setSteps(updatedSteps);
  };

  const getPage = (children: any) => {
    switch (activeStep) {
      case 0:
        return (
          <>
            <Container centerContent py={10}>
              <Heading as="h3" size="lg" mb={6} textAlign="center">
                What do you want to change?
              </Heading>
              <div className="w-100">
                <VStack spacing={4}>
                  <FormControl id="goal">
                    <Input
                      type="text"
                      placeholder="Expand this task to include..."
                      value={edit}
                      onChange={(e) => setEditState(e.target.value)}
                      bg="white"
                    />
                  </FormControl>
                  {children}
                </VStack>
              </div>
            </Container>
          </>
        );
      case 1:
        return (
          <>
            <Container centerContent py={10}>
              <Heading as="h4" size="lg" mb={6} textAlign="center">
                What materials would you like to use?
              </Heading>
              <div>
                <VStack spacing={4} justify="center">
                  <FormControl id="goal">
                    <CheckboxGroup
                      colorScheme="blue"
                      value={selectedOptions}
                      onChange={(value) => setSelectedOptions(value as never[])}
                    >
                      <HStack wrap="wrap" spacing={4}>
                        {materials.map((material) => (
                          <Checkbox value={material}>
                            <Badge
                              colorScheme="blue"
                              px={4}
                              py={2}
                              borderRadius="full"
                            >
                              {material}
                            </Badge>
                          </Checkbox>
                        ))}
                      </HStack>
                    </CheckboxGroup>
                  </FormControl>

                  {children}
                </VStack>
              </div>
            </Container>
          </>
        );
    }
  };

  const isDisabled = false; //this is a premium feature!

  const handleKeyDown = (event: any) => {
    if (isDisabled) return;
    if (event.key === "Enter" && event.shiftKey) {
      if (activeStep > 0) {
        setActiveStep(activeStep - 1);
      }
    } else if (event.key === "Enter") {
      console.log("hit");
      if (!isOpen) {
        return;
      } else if (activeStep === steps.length - 1) {
        setActiveStep(activeStep);
        generateRoadmap();
      } else if (
        activeStep < steps.length - 1 &&
        steps[activeStep + 1].canProceed
      ) {
        setActiveStep(activeStep + 1);
      }
    } else if (event.key === "Escape") {
      closeAndReset();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeStep, isOpen, steps]);

  const store = useContext(StoreContext);
  const toast = useToast();

  const generateRoadmap = async () => {
    console.log(task);
    const data = {
      edit,
      task,
      roadmapId,
      materials: selectedOptions,
      taskId: task._id,
    };
    closeAndReset();
    const promise = store.regenerateRoadmap(data);
    toast.promise(promise, {
      success: {
        title: "Roadmap Update Complete",
        description: "Your roadmap has been updated!",
        position: "top-right",
      },
      error: {
        title: "Roadmap Update Failed",
        description: "Something went wrong. Please try again later.",
        position: "top-right",
      },
      loading: {
        title: "Roadmap Update in Progress",
        description: "This may take a few moments.",
        position: "top-right",
      },
    });
  };

  return (
    <>
      <Button
        fontWeight="bold"
        color={textColor}
        fontSize="md"
        letterSpacing={1}
        bgColor={newBadgeColor}
        zIndex={100}
        onClick={(e) => {
          e.stopPropagation();
          onOpen();
        }}
        size={size}
      >
        <i className={`fa-solid fa-arrows-rotate text-${fontSize}`}></i>
      </Button>

      <Modal isOpen={isOpen} onClose={closeAndReset} size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Stepper size="sm" index={activeStep}>
              {steps.map((step, index) => (
                <Step
                  key={index}
                  onClick={
                    step.canProceed ? () => setActiveStep(index) : undefined
                  }
                  style={{
                    pointerEvents: step.canProceed ? "auto" : "none",
                    opacity: step.canProceed ? 1 : 0.5,
                  }}
                >
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <StepTitle className="mt-2">{step.description}</StepTitle>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
          </ModalHeader>
          <HSeparator />
          <ModalBody>
            {getPage(
              <>
                {activeStep < steps.length - 1 ? (
                  <Button
                    colorScheme="blue"
                    size="md"
                    width="full"
                    bgGradient="linear(to-r, blue.400, purple.500 80%)"
                    position="relative"
                    overflow="hidden"
                    _before={{
                      position: "absolute",
                      content: `""`,
                      width: "100%",
                      height: "100%",
                      bgGradient: "linear(to-r, purple.500, blue.400 80%)",
                      transition: "opacity .7s ease",
                    }}
                    _hover={{
                      _before: { opacity: 0 },
                    }}
                    onClick={() => {
                      setActiveStep(activeStep + 1);
                    }}
                  >
                    <Box position="relative" zIndex={1}>
                      Next
                    </Box>
                  </Button>
                ) : (
                  <Button
                    colorScheme="blue"
                    size="md"
                    width="full"
                    bgGradient="linear(to-r, blue.400, purple.500 80%)"
                    position="relative"
                    overflow="hidden"
                    _before={{
                      position: "absolute",
                      content: `""`,
                      width: "100%",
                      height: "100%",
                      bgGradient: "linear(to-r, purple.500, blue.400 80%)",
                      transition: "opacity .7s ease",
                    }}
                    _hover={{
                      _before: { opacity: 0 },
                    }}
                    onClick={generateRoadmap}
                  >
                    <Box position="relative" zIndex={1}>
                      Generate
                    </Box>
                  </Button>
                )}
              </>
            )}
          </ModalBody>
          <HSeparator />

          <ModalFooter>
            <>
              <Flex width="100%" justify="space-between">
                {activeStep > 0 ? (
                  <Button
                    variant="ghost"
                    onClick={() => {
                      setActiveStep(activeStep - 1);
                    }}
                  >
                    <span className="font-bold" color={inputColor}>
                      Back{" "}
                    </span>{" "}
                    <Kbd className="ml-2 mr-1" color={inputColor}>
                      Shift
                    </Kbd>{" "}
                    +
                    <Kbd className="ml-1" color={inputColor}>
                      Enter
                    </Kbd>
                  </Button>
                ) : (
                  <div />
                )}
                {activeStep < steps.length - 1 ? (
                  <Button
                    variant="ghost"
                    size="md"
                    onClick={() => {
                      setActiveStep(activeStep + 1);
                    }}
                  >
                    <span className="font-bold">Next </span>{" "}
                    <Kbd color={inputColor} className="ml-2">
                      Enter
                    </Kbd>
                  </Button>
                ) : (
                  <Button variant="ghost" onClick={generateRoadmap}>
                    <span className="font-bold">Finish </span>{" "}
                    <Kbd color={inputColor} className="ml-2">
                      Enter
                    </Kbd>
                  </Button>
                )}{" "}
              </Flex>
            </>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RoadmapEditModal;
