import React, { useState, useContext } from "react";
import "reactflow/dist/style.css";
import { IRoadmap, TaskSchema } from "../../Core/Types";
import {
  Box,
  VStack,
  HStack,
  Button,
  useDisclosure,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Text,
  Badge,
  useColorModeValue,
  position,
  Flex,
} from "@chakra-ui/react";
import { StoreContext } from "../../Contexts/DataStore";
import { TaskDrawer } from "./TaskCard";
import { Background, BackgroundVariant } from "reactflow";
import RoadmapEditModal from "./RoadmapEditModal";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import RoadmapDeleteModal from "./RoadmapDeleteModal";

export default function ListFlow({ roadMapData }: { roadMapData: IRoadmap }) {
  const gridColor = useColorModeValue("grey", "grey");

  return (
    <div className="rounded h-5/6 border-2 overflow-auto">
      <Box className="position-relative min-h-full min-w-100">
        <Box ml={0} mt={2} mb={2} style={{ position: "relative", zIndex: 1 }}>
          {/* <TransformWrapper
            minScale={1}
            maxScale={6}
            wheel={{ step: 0.1 }}
            doubleClick={{ disabled: true }}
            panning={{ disabled: false }}
            disablePadding
          >
            <TransformComponent> */}
          <Background
            variant={BackgroundVariant.Dots}
            size={1.5}
            color={gridColor}
          />
          {roadMapData.tasks.map((task, index) => {
            const data = { task, depth: 0, roadmapId: roadMapData._id };
            return <TaskCard key={index} data={data} />;
          })}
          {/* </TransformComponent>
          </TransformWrapper> */}
        </Box>
      </Box>
    </div>
  );
}

export function TaskCard({
  data,
}: {
  data: { task: TaskSchema; depth?: number; roadmapId: string };
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [expanded, setExpanded] = useState(true);
  const task = data?.task;
  const depth = data?.depth ?? 0;

  const greenBadge = useColorModeValue("green", "lime");
  const blueBadge = useColorModeValue("blue", "cyan");
  const newBadgeColor = useColorModeValue("purple.300", "purple.500");
  const textColor = useColorModeValue("white", "black");

  const store = useContext(StoreContext);

  const LINE_LENGTH = 60;

  return (
    <>
      <HStack alignItems="center" spacing={0} position="relative">
        {task && (
          <Box
            left={depth > 0 ? `${LINE_LENGTH + 40}px` : 0}
            position="relative"
          >
            <Box position="absolute" width="2px" height="110%" bg="gray.300">
              <Box
                position="absolute"
                top="0"
                left="0"
                width="2px"
                height="100%"
                bg="purple.300"
              />
            </Box>

            <Box mt={2} pointerEvents="auto">
              <Box
                position="relative"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  width="8px"
                  height="8px"
                  borderRadius="50%"
                  bg="purple.300"
                  position="relative"
                />
                <Box
                  position="relative"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width={`${LINE_LENGTH}px`}
                  height="2px"
                  bg="purple.300"
                >
                  {task?.tasks?.length > 0 && (
                    <Button
                      borderRadius="50%"
                      size="xs"
                      bg="purple.300"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      position="absolute"
                      transform="translateX(-50%)"
                      onClick={(e) => {
                        e.stopPropagation();
                        setExpanded(!expanded);
                      }}
                    >
                      {!expanded ? (
                        <Text className="fa-solid fa-angles-up text-md font-bold mt-3"></Text>
                      ) : (
                        <Text className="fa-solid fa-angles-down text-md font-bold mt-3"></Text>
                      )}
                    </Button>
                  )}
                </Box>
                <Box
                  width="8px"
                  height="8px"
                  borderRadius="50%"
                  bg="purple.300"
                  position="relative"
                />
                {task?._id && (
                  <>
                    <Flex
                      position="absolute"
                      px="2"
                      py="1"
                      borderRadius="md"
                      textAlign="center"
                      justifyContent={"center"}
                      justify="center"
                      align="center"
                      w="1.75vw"
                      top="-2.75%"
                      left="97.50%"
                    >
                      <RoadmapEditModal
                        task={task}
                        roadmapId={data.roadmapId}
                        size="xs"
                        fontSize="xs"
                      />
                    </Flex>
                    <Flex
                      position="absolute"
                      px="2"
                      py="1"
                      borderRadius="md"
                      textAlign="center"
                      justifyContent={"center"}
                      justify="center"
                      align="center"
                      w="1.75vw"
                      top="82%"
                      left="97.5%"
                    >
                      <RoadmapDeleteModal
                        task={task}
                        roadmapId={data.roadmapId}
                        size="xs"
                        fontSize="xs"
                      ></RoadmapDeleteModal>
                    </Flex>
                  </>
                )}

                <Card
                  size="sm"
                  w="32rem"
                  borderRadius="md"
                  boxShadow="md"
                  borderColor="purple.300"
                  className={task.new ? "radiating-purple-border-small" : ""}
                  borderWidth={2}
                  onClick={(e) => {
                    e.stopPropagation();
                    onOpen();
                  }}
                >
                  <CardHeader p={3} pb={1}>
                    <HStack justifyContent="space-between" w="100%">
                      <Heading fontSize={"15"} mt={1}>
                        {task.taskName}
                      </Heading>
                      <HStack spacing={2}>
                        <Badge
                          fontSize={"xs"}
                          color={blueBadge}
                          variant="subtle"
                        >
                          {task.type}
                        </Badge>
                        {task?.tasks?.length > 0 && (
                          <Badge
                            fontSize={"xs"}
                            color={greenBadge}
                            variant="subtle"
                          >
                            {task?.tasks?.length} Subtasks
                          </Badge>
                        )}
                      </HStack>
                    </HStack>
                  </CardHeader>
                  <Box borderBottomWidth="1px" />
                  <CardBody p={4} pb={3} pt={1}>
                    <Text fontSize="sm" noOfLines={2} mb={2}>
                      {task.overview}
                    </Text>
                    {task?.materials?.length > 0 && (
                      <Button
                        variant="link"
                        colorScheme="blue"
                        onClick={(e) => {
                          e.stopPropagation();
                          onOpen();
                        }}
                        fontSize="sm"
                        mt={0}
                      >
                        ({task.materials.length}) Linked Material
                        {task.materials.length > 1 && "s"}
                      </Button>
                    )}
                  </CardBody>
                </Card>
              </Box>

              {expanded && task?.tasks?.length > 0 && (
                <VStack align="start" spacing={1} mt={2}>
                  {task?.tasks?.map((subtask) => (
                    <TaskCard
                      data={{
                        task: subtask,
                        depth: depth + 1,
                        roadmapId: data.roadmapId,
                      }}
                    />
                  ))}
                </VStack>
              )}
            </Box>
          </Box>
        )}
      </HStack>

      <TaskDrawer isOpen={isOpen} onClose={onClose} task={task} />
    </>
  );
}
