import {
  Button,
  Flex,
  Link,
  Img,
  Text,
  useColorModeValue,
  Progress,
} from "@chakra-ui/react";
import { useContext } from "react";
import { StoreContext } from "../../../../Contexts/DataStore";

export default function SidebarDocs() {
  const bgColor = useColorModeValue(
    "linear(to-r, blue.100, purple.100 80%)",
    "linear(to-r, blue.800, purple.700 80%)"
  );
  const borderColor = useColorModeValue("black", "white");

  const barColor = useColorModeValue("purple", "purple");

  const store = useContext(StoreContext);

  return (
    <Flex
      justify="center"
      direction="column"
      align="center"
      bgGradient={bgColor}
      borderRadius="16px"
      position="relative"
    >
      <Flex
        direction="column"
        mb="12px"
        align="center"
        justify="center"
        px="15px"
        pt="25px"
      >
        <Text
          fontSize={{ base: "lg", xl: "18px" }}
          color={borderColor}
          fontWeight="bold"
          lineHeight="150%"
          textAlign="center"
          mb="14px"
        >
          Remaining Roadmaps
        </Text>
        <Progress
          hasStripe
          colorScheme={barColor}
          value={(store.user?.totalGenerations ?? 0) * 10}
          size="lg"
          className="w-75"
        />
        <Text
          fontSize="14px"
          color={borderColor}
          mb="10px"
          textAlign="center"
          fontWeight={"bold"}
        >
          {" "}
          {store.user?.totalGenerations ?? 0}/
          {store.user?.role === "Developer" ? (
            <i className="fa-solid fa-infinity "></i>
          ) : (
            10
          )}{" "}
          Used
        </Text>
        <Text
          fontSize="14px"
          color={borderColor}
          fontWeight={""}
          mb="2px"
          textAlign="center"
        >
          No more roadmaps? No worries! In the next update, access public
          roadmaps for free or create new ones with a premium plan.
        </Text>
      </Flex>
      <Button
        bg="navy.400"
        _hover={{ bg: "whiteAlpha.200" }}
        _active={{ bg: "whiteAlpha.100" }}
        mb={"12px"}
        textColor={"white"}
        fontWeight="regular"
        fontSize="sm"
        minW="185px"
        mx="auto"
        borderRadius="45px"
        border={2}
        borderColor="black"
        isDisabled={true} // Disables the button
        onClick={() =>
          alert(
            "This feature is not implemented yet. Soon you will be able to create more roadmaps!"
          )
        }
      >
        Upgrade
      </Button>
    </Flex>
  );
}
