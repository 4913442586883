import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Text,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { StoreContext } from "../Contexts/DataStore";
import { successToast } from "../Components/Misc/Toasts";
import fullLogoDark from "../img/logos/bannerDark.png";
import fullLogoLight from "../img/logos/bannerLight.png";

export const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [generalError, setGeneralError] = useState<string | null>(null);
  const store = useContext(StoreContext);
  const navigate = useNavigate();

  const inputTextColor = useColorModeValue("gray.800", "gray.200");
  const pageBg = useColorModeValue(
    "linear(to-b, purple.100, gray.100 50%, gray.100)",
    "linear(to-b, purple.900, navy.900 60%, navy.900)"
  );
  const boxCol = useColorModeValue("white", "gray.700");
  const submitBg = useColorModeValue("purple.400", "purple.600");
  const logoImg = useColorModeValue(fullLogoLight, fullLogoDark);

  const sendEmail = async (credentials: FieldValues) => {
    const result = await store.sendResetEmail(credentials);
    if (result?.status !== 200) {
      setGeneralError(
        result?.data?.message ?? "Something went wrong. Please try again later."
      );
    } else {
      successToast("Email sent successfully. Check your inbox.");
      navigate("/resetpassword/pin", { state: { email: credentials.email } });
    }
  };

  const handleClearErrors = () => {
    clearErrors();
    setGeneralError(null);
  };

  return (
    <div>
      <Flex
        minH={"100vh"}
        align={"center"}
        justify={"center"}
        bgGradient={pageBg}
        p={4}
      >
        <form onSubmit={handleSubmit((data) => sendEmail(data))}>
          <Stack
            spacing={4}
            mx={"auto"}
            maxW={"lg"}
            py={12}
            px={6}
            width={{ base: "90vw", md: "70vw", lg: "50vw" }}
          >
            <Stack align={"center"}>
              <Text
                fontSize={{ base: "2xl", md: "4xl" }}
                fontWeight={"bold"}
                mb="0"
              >
                Reset Password
              </Text>
            </Stack>
            <Box rounded={"lg"} bg={boxCol} boxShadow={"xl"} p={8}>
              <Stack spacing={4}>
                {/* <Box justifyContent="center" display="flex" className="mb-3">
                  <Image
                    src={logoImg}
                    alt="My Icon"
                    pointerEvents="none"
                    width={{ base: "200px", md: "300px" }}
                    height={{ base: "40px", md: "50px" }}
                  />
                </Box> */}
                <FormControl id="email" isRequired>
                  <FormLabel>Email address</FormLabel>
                  <Input
                    color={inputTextColor}
                    {...register("email", {
                      required: true,
                      pattern: /^\S+@\S+$/i,
                    })}
                  />
                  {errors.email && (
                    <Text color="red.500" mb={0} ml={2}>
                      A valid email is required.
                    </Text>
                  )}
                </FormControl>
                <Stack spacing={10}>
                  <Button
                    bgColor={submitBg}
                    color={"white"}
                    type="submit"
                    onClick={handleClearErrors}
                  >
                    Send Code
                  </Button>
                </Stack>
                {generalError && (
                  <Text color="red.500" textAlign="center">
                    {generalError}
                  </Text>
                )}
                <Stack pt={2}>
                  <Text
                    align={"center"}
                    onClick={() => navigate("/login")}
                    mb={0}
                  >
                    <Link color={"blue.400"}>
                      <i className="fa-solid fa-arrow-left"></i> Back to Log in
                    </Link>
                  </Text>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </form>
      </Flex>
    </div>
  );
};
