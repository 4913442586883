import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { allRoutes, authRoutes, protectedRoutes } from "./Routes";
import { IUser, RouteType } from "../Core/Types";
import RootLayout from "../Components/HorizonComponents/layout";
import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../Contexts/DataStore";
import { jwtDecode } from "jwt-decode";
import usePromise from "../Hooks/UsePromise";
import { LoadScreen } from "../Components/Misc/LoadScreen";
import { Box } from "@chakra-ui/react";

const DEFAULT_PAGE = "/dashboard";

const AppRoutes = () => {
  const [authChecking, setAuthChecking] = useState<boolean>(true);

  const store = useContext(StoreContext);
  const isLoggedIn = store.isLoggedIn;
  const [getUserState, trackGetUserState] = usePromise();

  useEffect(() => {
    (async () => {
      await trackGetUserState(store.getUserData());
      setAuthChecking(false);
    })();
  }, [isLoggedIn]);

  if (authChecking || getUserState.loading) return <LoadScreen />;
  return (
    <BrowserRouter>
      <Routes>
        {!isLoggedIn &&
          authRoutes.map((route: RouteType, index: number) => (
            <Route
              key={index}
              path={route.path}
              element={
                !isLoggedIn ? (
                  <route.component />
                ) : (
                  <Navigate to={DEFAULT_PAGE} replace />
                )
              }
            />
          ))}

        {isLoggedIn &&
          protectedRoutes.map((route: RouteType, index: number) => (
            <Route
              key={index}
              path={route.path}
              element={
                isLoggedIn && !authChecking ? (
                  <RootLayout rootName={route.name ?? "Home"}>
                    <route.component />
                  </RootLayout>
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
          ))}

        {/* Default route */}
        <Route path="/" element={<Navigate to={DEFAULT_PAGE} replace />} />
        {
          <Route
            path="*"
            element={
              authChecking ? (
                <LoadScreen />
              ) : isLoggedIn ? (
                <Navigate to={DEFAULT_PAGE} replace />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
        }
      </Routes>
    </BrowserRouter>
  );
};

export default observer(AppRoutes);
