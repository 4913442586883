import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  NodeTypes,
  BackgroundVariant,
  useReactFlow,
  ReactFlowProvider,
  MarkerType,
} from "reactflow";

import "reactflow/dist/style.css";
import { IRoadmap, RoadmapSchema, TaskSchema } from "../../Core/Types";
import { TaskCard } from "./TaskCard";
import { useColorMode, useColorModeValue, Text, Box } from "@chakra-ui/react";
import CircleNode from "./CircleNode";
import FinalNode from "./FinalNode";

const nodeTypes = {
  "task-card": TaskCard,
  circle: CircleNode,
  "final-node": FinalNode,
} satisfies NodeTypes;

export default function RoadmapFlow({
  roadMapData,
}: {
  roadMapData: IRoadmap;
}) {
  const reactFlowWrapper = useRef<any>(null);

  const gridColor = useColorModeValue("grey", "grey");

  // A bit convulted, used to dyanmically create nodes and edges for React Flow
  const MIN_ZOOM = 0.1;
  const MAX_ZOOM = 1;

  const edgeProps = {
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 6,
      height: 6,
      color: "purple",
    },
    style: {
      strokeWidth: 6,
      stroke: "purple",
    },
    animated: true,
  };

  const sizeFactor = 425;

  //https://reactflow.dev/learn/layouting/layouting
  const recusriveCreateNodes = (
    tasks: TaskSchema[],
    index: number = 0,
    depth: number = 0,
    orientation: 1 | -1 | 0 = 0
  ) => {
    //init node and edges + counters
    let nodes: any[] = [];
    let edges: any[] = [];
    let curIndex = index;
    let prevIndex = 0;
    let surfaceTask = -1;

    tasks?.forEach((subTask, subIndex) => {
      let nodeId;
      if (index === 0 && subIndex === tasks.length - 1) {
        nodeId = `finish`;
      } else {
        nodeId = `${index}-${curIndex}`;
      }

      if (index === 0) {
        surfaceTask++;
        orientation = subIndex % 2 !== 0 ? 1 : -1;
      }

      const horizontalStretch = 1.5;
      const overallStretch = 1.25;
      const isConstantLevel = index + subIndex === curIndex;

      const baseStretch = (curIndex * sizeFactor) / horizontalStretch;
      const leafStretch = ((curIndex - depth) * sizeFactor) / horizontalStretch;

      const x =
        curIndex * sizeFactor * overallStretch -
        (index === 0 && !subTask?.tasks?.length
          ? 0
          : index === 0
          ? baseStretch
          : leafStretch);

      const y =
        index !== 0 ? orientation * ((depth / overallStretch) * sizeFactor) : 0;

      nodes.push({
        id: nodeId,
        type: "task-card",
        position: { x, y },
        data: { task: subTask, roadmapId: roadMapData._id },
      });

      if (index !== curIndex) {
        edges.push({
          id: `e${nodeId}`,
          source: `${index}-${curIndex - prevIndex - 1}`,
          target: nodeId,
          ...edgeProps,
        });
      }

      curIndex++;

      if (subTask?.tasks?.length > 0) {
        const { newIndex, newNodes, newEdges } = recusriveCreateNodes(
          subTask?.tasks,
          curIndex,
          depth + 1,
          orientation
        );

        if (newNodes.length > 0) {
          edges.push({
            id: `e${index}-${curIndex}`,
            source: nodeId,
            target: `${curIndex}-${curIndex}`,
            sourceHandle: orientation === 1 ? "bottom" : "top",
            ...edgeProps,
          });
        }
        prevIndex = newIndex - curIndex;
        curIndex = newIndex;
        nodes = [...nodes, ...newNodes];
        edges = [...edges, ...newEdges];
      } else {
        isConstantLevel && depth++;
      }
    });

    return { newIndex: curIndex, newNodes: nodes, newEdges: edges };
  };

  const { initialNodes, initialEdges } = useMemo(() => {
    if (roadMapData.tasks.length > 0) {
      const { newNodes, newEdges } = recusriveCreateNodes(roadMapData.tasks);

      const lastMainNodeX = newNodes?.find((node) => node.id === `finish`)
        ?.position?.x;
      const lastNodeX = newNodes[newNodes.length - 1]?.position?.x;
      const avgLastX = (lastMainNodeX + lastNodeX) / 2;
      const isFirstAndLast = newNodes?.find((node) => node.id === `0-0`);
      return {
        initialNodes: [
          {
            id: "start",
            type: "circle",
            position: { x: -500, y: 50 },
            data: { message: "Start" },
          },
          ...newNodes,
          {
            id: "end",
            type: "circle",
            position: { x: avgLastX + 650, y: 50 },
            data: { message: "Finish" },
          },
        ],
        initialEdges: [
          {
            id: `eStart`,
            source: "start",
            target: isFirstAndLast ? "0-0" : "finish",
            sourceHandle: "right",
            targetHandle: "left",
            ...edgeProps,
          },
          ...newEdges,
          {
            id: `eEnd`,
            source: `finish`,
            target: `end`,
            ...edgeProps,
          },
        ],
      };
    } else {
      return {
        initialNodes: [
          {
            id: "start",
            type: "circle",
            position: { x: -500, y: 50 },
            data: { message: "Start" },
          },
          {
            id: "middle",
            type: "final-node",
            position: { x: 75, y: 50 },
            data: { message: "Finish" },
          },
          {
            id: "end",
            type: "circle",
            position: { x: 650, y: 50 },
            data: { message: "Finish" },
          },
        ],
        initialEdges: [
          {
            id: `eStart`,
            source: "start",
            target: "middle",
            sourceHandle: "right",
            targetHandle: "left",
            ...edgeProps,
          },
          {
            id: `eEnd`,
            source: `middle`,
            target: `end`,
            ...edgeProps,
          },
        ],
      };
    }
  }, [roadMapData.tasks, recusriveCreateNodes, roadMapData]);

  return (
    <>
      <Box
        className="rounded border-2 " // Added bg-red-200 for visualization
        style={{ height: "73.5vh" }}
      >
        <ReactFlow
          nodes={initialNodes.map((node) => ({
            ...node,
            draggable: false,
          }))}
          edges={initialEdges}
          nodeTypes={nodeTypes}
          zoomOnScroll={true}
          zoomOnPinch={true}
          panOnDrag={true}
          elementsSelectable={false}
          nodesConnectable={false}
          nodesDraggable={false}
          edgesFocusable={false}
          fitView={false}
          zoomOnDoubleClick={false}
          minZoom={MIN_ZOOM}
          maxZoom={MAX_ZOOM}
          defaultViewport={{ x: 250, y: 300, zoom: 0.4 }}
        >
          {/* <Controls showInteractive={false} /> */}
          <Background
            variant={BackgroundVariant.Dots}
            color={gridColor}
            size={4}
            gap={55}
          />{" "}
          {/* <DownloadButton /> */}
          {/* <MiniMap nodeStrokeWidth={3} /> */}
        </ReactFlow>
      </Box>
    </>
  );
}
