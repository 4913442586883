import { makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext } from "react";
import { IRoadmap, IUser, RoadmapStats } from "../Core/Types";
import { jwtDecode } from "jwt-decode";
import CustomAxios from "../Utils/CustomAxios";

const defaultRoadmapStats: RoadmapStats = {
  totalRoadmaps: 0,
  roadmapGrowth: 0,
  totalTasks: 0,
  taskGrowth: 0,
  totalMaterials: 0,
  materialGrowth: 0,
};

export class DataStore {
  constructor() {
    makeAutoObservable(this);
    CustomAxios.configureGlobalDefaults();
  }

  isLoggedIn: boolean | undefined = undefined;
  user: IUser | null = null;
  roadmaps: IRoadmap[] = [];
  roadmapsLoaded = false;
  userRoadmapStats: RoadmapStats = defaultRoadmapStats;
  isUserRoadmapStatsLoaded = false;

  logOut() {
    this.user = null;
    this.isLoggedIn = false;
    this.roadmapsLoaded = false;
    this.userRoadmapStats = defaultRoadmapStats;
    this.isUserRoadmapStatsLoaded = false;
    localStorage.removeItem("jwt-token");
    sessionStorage.removeItem("userId");
  }

  async getRoadmaps(): Promise<IRoadmap[] | undefined> {
    try {
      if (this.roadmapsLoaded) return;
      const response = await CustomAxios.get("/api/roadmap/userroadmaps");
      this.roadmaps = response.data.roadmaps;
      this.roadmapsLoaded = true;
    } catch (error: any) {
      return error.response;
    }
  }

  async getUserData(): Promise<IUser | undefined> {
    try {
      if (this.user) return;
      let email = null;
      const token =
        localStorage.getItem("jwt-token") ||
        sessionStorage.getItem("jwt-token");
      if (token) {
        const decodedUser = jwtDecode(token) as any;
        email = decodedUser?.email;
      }
      const userSessionToken = sessionStorage.getItem("userId");
      if ((email && !this.user) || userSessionToken) {
        const body = { email };
        const response = await CustomAxios.post("/api/user", body);
        this.user = response.data.user;
        if (this.user) {
          this.isLoggedIn = true;
          sessionStorage.setItem("userId", this.user._id);
        }
      }
    } catch (error: any) {
      return error.response;
    }
  }

  async generateRoadmap(body: any): Promise<string | undefined> {
    const response = await CustomAxios.post("/api/roadmap/generate", body);
    this.roadmaps = [{ ...response.data.roadmap, new: true }, ...this.roadmaps];
    if (this.user) {
      this.user.totalGenerations = response.data.totalGenerations;
    }
    this.isUserRoadmapStatsLoaded = false;
    return response.data;
  }

  async regenerateRoadmap(body: any): Promise<string | undefined> {
    const response = await CustomAxios.post("/api/roadmap/regenerate", body);

    const updatedRoadmap = response.data.roadmap;
    const index = this.roadmaps.findIndex(
      (roadmap: any) => roadmap.id === updatedRoadmap.id
    );
    if (index !== -1) {
      // force higher level useffect to re-render (see YourRoadmap)
      this.roadmaps = [
        ...this.roadmaps.slice(0, index),
        updatedRoadmap,
        ...this.roadmaps.slice(index + 1),
      ];
    }
    this.isUserRoadmapStatsLoaded = false;
    return response.data;
  }

  async deleteRoadmapTask(body: any): Promise<string | undefined> {
    try {
      const response = await CustomAxios.post("/api/roadmap/task/delete", body);

      const updatedRoadmap = response.data.roadmap;
      const index = this.roadmaps.findIndex(
        (roadmap: any) => roadmap.id === updatedRoadmap.id
      );
      if (index !== -1) {
        // force higher level useffect to re-render (see YourRoadmap)
        this.roadmaps = [
          ...this.roadmaps.slice(0, index),
          updatedRoadmap,
          ...this.roadmaps.slice(index + 1),
        ];
      }
      this.isUserRoadmapStatsLoaded = false;
      return response.data;
    } catch (error: any) {
      console.error(error);
    }
  }

  async deleteRoadmap(body: any): Promise<string | undefined> {
    try {
      const response = await CustomAxios.post("/api/roadmap/delete", body);
      this.roadmaps = this.roadmaps.filter(
        (roadmap) => roadmap?._id !== response.data.roadmapId
      );
      this.isUserRoadmapStatsLoaded = false;
      return response.data;
    } catch (error: any) {
      return error.response;
    }
  }

  async signUp(body: Partial<IUser>, rememberMe: boolean): Promise<any> {
    try {
      const response = await CustomAxios.post("/api/user/signup", body);
      if (response?.data && response?.status === 200) {
        this.user = response.data.user;
        this.isLoggedIn = true;
        sessionStorage.setItem("userId", response.data.user._id);
        if (response.data.token && rememberMe) {
          localStorage.setItem("jwt-token", response.data.token);
        }
      }
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async logIn(body: any, rememberMe: boolean): Promise<any> {
    try {
      const response = await CustomAxios.post("/api/user/login", body);
      if (response?.data && response?.status === 200) {
        this.user = response.data.user;
        this.isLoggedIn = true;
        sessionStorage.setItem("userId", response.data.user._id);
        if (response.data.token && rememberMe) {
          localStorage.setItem("jwt-token", response.data.token);
        }
      }
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async sendResetEmail(body: any): Promise<any> {
    try {
      const response = await CustomAxios.post("/api/user/sendresetemail", body);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async verifyResetPin(body: any): Promise<any> {
    try {
      const response = await CustomAxios.post(
        "/api/user/validateresetpin",
        body
      );
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async resetPassword(body: any): Promise<any> {
    try {
      const response = await CustomAxios.post("/api/user/resetpassword", body);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async updateDetails(body: any): Promise<any> {
    try {
      const response = await CustomAxios.post("/api/user/updatedetails", body);
      if (response?.data && response?.status === 200 && this.user) {
        this.user.userDetails = response.data?.user?.userDetails;
      }
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async editRoadmap(body: any): Promise<any> {
    try {
      const response = await CustomAxios.patch("/api/roadmap", body);
      const updatedRoadmap: IRoadmap = response.data.roadmap;
      const index = this.roadmaps.findIndex(
        (r) => r._id === updatedRoadmap._id
      );

      if (index !== -1) {
        this.roadmaps[index] = updatedRoadmap;
      } else {
        console.error("Roadmap not found");
      }
      return response;
    } catch (error: any) {
      console.log(error);
      return error.response;
    }
  }

  async getRoadmapStats(): Promise<any> {
    try {
      if (this.isUserRoadmapStatsLoaded) return this.userRoadmapStats;
      const response = await CustomAxios.get("/api/roadmap/stats");
      const stats: RoadmapStats = response.data.stats;
      this.userRoadmapStats = stats;
      this.isUserRoadmapStatsLoaded = true;
      return stats;
    } catch (error: any) {
      console.log(error);
      return error.response;
    }
  }
}

export const dataStore = new DataStore();
export const StoreContext = createContext(dataStore);
