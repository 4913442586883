"use client";
/* eslint-disable */
// Chakra Imports
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { isWindowAvailable } from "../../../Utils/navigation";
import DynamicBreadcrumb from "../../../Routes/DynamicBreadcrumb";
import { protectedRoutes } from "../../../Routes/Routes";
import { SidebarResponsive } from "../sidebar/Sidebar";

export default function AdminNavbar(props: {
  secondary: boolean;
  brandText: string;
  logoText: string;
  onOpen: (...args: any[]) => any;
  setApiKey: any;
}) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    isWindowAvailable() && window.addEventListener("scroll", changeNavbar);

    return () => {
      isWindowAvailable() && window.removeEventListener("scroll", changeNavbar);
    };
  });

  const { secondary, brandText, setApiKey } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)

  let navbarPosition = "fixed" as const;
  let navbarFilter = "none";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBg = useColorModeValue(
    "linear(to-b, purple.100, gray.100 50%, gray.100)",
    "linear(to-b, purple.900, navy.900 60%, navy.900)"
  );
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let gap = "0px";
  const changeNavbar = () => {
    if (isWindowAvailable() && window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const routes = protectedRoutes;

  return (
    <Box
      zIndex="100"
      position={navbarPosition}
      bgGradient={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition="center"
      backgroundSize="cover"
      borderBottomRadius={"10px"}
      p="4" // Adjusted padding
      alignItems={{ xl: "center" }}
      display={secondary ? "block" : "flex"}
      minH="60px" // Reduced minimum height
      justifyContent={{ xl: "center" }}
      lineHeight="20px" // Adjusted line height
      mt={secondaryMargin}
      pb="6px"
      mr="-14px"
      right={{ base: "0", xl: "30px" }}
      ps={{
        base: "8px",
        md: "12px",
      }}
      w={{
        base: "calc(100vw + 15px)",
        xl: "calc(100vw - 350px)",
        "2xl": "calc(100vw - 365px)",
      }}
    >
      <SidebarResponsive routes={routes} />
      <Flex
        w="100%"
        flexDirection={"row"}
        alignItems={{ xl: "center" }}
        mb={gap}
        mt="1" // Adjusted margin-top
        ml={{ base: "8", xl: "0" }}
      >
        <Box mb={{ base: "4px", md: "0px" }}>
          <DynamicBreadcrumb />
        </Box>
      </Flex>
    </Box>
  );
}
