import React, { useContext, useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useColorModeValue,
  Text,
  Link,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { toTitleCase } from "../Utils/UtilityFunctions";
import { StoreContext } from "../Contexts/DataStore";
import { observer } from "mobx-react-lite";

const DynamicBreadcrumb = observer(({ secondaryText }: any) => {
  const location = useLocation();
  const pathnames = location.pathname?.split("/").filter((x) => x);

  let mainText = useColorModeValue("navy.700", "white");

  const store = useContext(StoreContext);

  const [currentLocation, setCurrentLocation] = useState<string>("");

  const hash = location.hash ? location.hash.substring(1) : "";

  useEffect(() => {
    const hashToText = () => {
      switch (pathnames[pathnames.length - 1]) {
        case "roadmaps":
          if (hash) {
            const roadmap = store.roadmaps.find(
              (roadmap) => roadmap._id === hash
            );
            if (!roadmap && store.roadmaps) return "Roadmaps";
            return roadmap ? roadmap.roadmapName : "Loading...";
          } else {
            return "Roadmaps";
          }
        default:
          return toTitleCase(pathnames[pathnames.length - 1]);
      }
    };
    setCurrentLocation(hashToText());
  }, [location.hash, location.pathname, pathnames, store.roadmaps]);

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem color={secondaryText} fontSize="sm" isCurrentPage>
          <BreadcrumbLink color={secondaryText}>Pages</BreadcrumbLink>
        </BreadcrumbItem>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isFirst = index === 0;

          return (
            <BreadcrumbItem key={to} color={secondaryText} fontSize="sm">
              <BreadcrumbLink href={to} color={secondaryText}>
                {value.charAt(0).toUpperCase() + value.slice(1)}
              </BreadcrumbLink>
            </BreadcrumbItem>
          );
        })}
        {hash.length > 0 && (
          <BreadcrumbItem color={secondaryText} fontSize="sm" isCurrentPage>
            <BreadcrumbLink color={secondaryText}>
              {currentLocation}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}
      </Breadcrumb>
      <Text
        color={mainText}
        bg="inherit"
        borderRadius="inherit"
        fontWeight="bold"
        fontSize="34px"
        p="0px"
        _hover={{ color: { mainText } }}
        _active={{
          bg: "inherit",
          transform: "none",
          borderColor: "transparent",
        }}
        _focus={{
          boxShadow: "none",
        }}
        className="ml-8"
      >
        {currentLocation}
      </Text>
    </>
  );
});

export default DynamicBreadcrumb;
