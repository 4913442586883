"use client";
// chakra imports
import {
  AvatarBadge,
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Avatar,
  Text,
  useColorMode,
  useColorModeValue,
  Tooltip,
} from "@chakra-ui/react";
import { PropsWithChildren, useContext } from "react";
import SidebarCard from "./SidebarCard";
import Links from "./Links";
import Brand from "./Brand";
import { RouteType } from "../../../../Core/Types";
import { StoreContext } from "../../../../Contexts/DataStore";

import WorkInProgress from "../../../Misc/WorkInProgress";
// FUNCTIONS

interface ISidebarContent extends PropsWithChildren {
  routes: RouteType[];
  [x: string]: any;
}

function SidebarContent(props: ISidebarContent) {
  const { routes } = props;
  const textColor = useColorModeValue("navy.700", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.300");
  const bgColor = useColorModeValue("white", "navy.700");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(12, 44, 55, 0.18)"
  );
  const iconColor = useColorModeValue("navy.700", "white");
  const shadowPillBar = useColorModeValue(
    "4px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "none"
  );

  const { colorMode, toggleColorMode } = useColorMode();

  const colModeColor = useColorModeValue("navy.100", "navy.700");

  const gray = useColorModeValue("gray.500", "white");

  const store = useContext(StoreContext);
  // SIDEBAR
  return (
    <Flex
      direction="column"
      height="100%"
      pt="20px"
      pb="26px"
      borderRadius="30px"
      maxW="285px"
      px="20px"
    >
      <Brand />
      <Stack direction="column" mb="auto" mt="8px">
        <Box ps="0px" pe={"0px"}>
          <Links routes={routes} />
        </Box>
      </Stack>

      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <SidebarCard />
      </Box>
      <Button
        onClick={toggleColorMode}
        display="flex"
        fontSize={"sm"}
        fontWeight="600"
        borderRadius={"45px"}
        mt="8px"
        minH="40px"
        bgColor={colModeColor}
      >
        <Flex align="center">
          <i
            className={` ${
              colorMode === "light" ? "fa-solid fa-moon" : " fa-solid fa-sun"
            }`}
          ></i>
          <Text fontWeight="500" fontSize="sm" className="mt-3 ml-2">
            Toggle {colorMode === "light" ? "Dark" : "Light"} Mode
          </Text>
        </Flex>
      </Button>
      <Flex
        mt={2}
        justifyContent="center"
        alignItems="center"
        boxShadow={shadowPillBar}
        borderRadius="30px"
      >
        <Stack direction="row" spacing={4} className="mr-0">
          <Avatar bgGradient="linear(to-l, blue.500, purple.500)" size="sm">
            <AvatarBadge boxSize="1em" bg="green.500" />
          </Avatar>
        </Stack>
        <Stack direction="column" className="ml-2 mr-4 mt-3">
          <Text fontWeight="bold" color={textColor} fontSize="md" mb="-10px">
            {store.user?.firstName} {store.user?.lastName}
          </Text>
          <Text
            fontSize="xs"
            bgGradient="linear(to-l, blue.500, purple.500)"
            bgClip="text"
            fontWeight="extrabold"
            className="ml-0"
          >
            {store?.user?.role ?? "Beta User <3"}
          </Text>
        </Stack>

        <Menu>
          <Tooltip
            label="Account settings coming soon"
            aria-label="Account settings tooltip"
          >
            <div>
              <Button
                as={Button}
                variant="transparent"
                aria-label="Account settings"
                border="1px solid"
                borderColor={borderColor}
                borderRadius="full"
                w="34px"
                h="34px"
                px="0px"
                p="0px"
                minW="34px"
                me="10px"
                justifyContent="center"
                alignItems="center"
                color={"grey"}
                isDisabled={true}
              >
                <Flex align="center" justify="center">
                  <i className="fa-solid fa-gear"></i>
                </Flex>
              </Button>
            </div>
          </Tooltip>

          <MenuList
            p="25px"
            w="236px"
            borderRadius="16px"
            transform="translate(-43px, -80px)!important"
            border="0px"
            boxShadow={shadow}
            bg={bgColor}
            zIndex={1000}
          >
            {" "}
            <Box>
              <Flex align="center" w="100%">
                <i className="fa-solid fa-user"> </i>
                <Text fontWeight="500" fontSize="sm" className="mt-3 ml-2">
                  Profile Settings
                </Text>
              </Flex>
            </Box>
            <Box>
              <Flex align="center">
                <i className="fa-solid fa-clock-rotate-left"></i>{" "}
                <Text fontWeight="500" fontSize="sm" className="mt-3 ml-2">
                  History
                </Text>
              </Flex>
            </Box>
            <Box>
              <Flex align="center">
                <i className="fa-solid fa-chart-line"></i>
                <Text fontWeight="500" fontSize="sm" className="mt-3 ml-2">
                  Usage
                </Text>
              </Flex>
            </Box>
            <Box>
              <Flex align="center">
                <i className="fa-solid fa-gear"></i>
                <Text fontWeight="500" fontSize="sm" className="mt-3 ml-2">
                  My Plan
                </Text>
              </Flex>
            </Box>{" "}
          </MenuList>
        </Menu>
        <Button
          variant="transparent"
          border="1px solid"
          borderColor={borderColor}
          borderRadius="full"
          w="34px"
          h="34px"
          px="0px"
          minW="34px"
          justifyContent={"center"}
          alignItems="center"
          onClick={() => store.logOut()}
        >
          <i className="fa-solid fa-arrow-right-from-bracket"></i>
        </Button>
      </Flex>
    </Flex>
  );
}

export default SidebarContent;
