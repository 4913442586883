"use client";
/*eslint-disable*/

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Icon,
  Img,
  Input,
  Skeleton,
  Spinner,
  Stack,
  Stat,
  StatArrow,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  Textarea,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../Contexts/DataStore";
import usePromise from "../Hooks/UsePromise";
import { useNavigate } from "react-router-dom";
import useDebounce from "../Hooks/UseDebounce";
import UserInfoInput from "../Components/Dashboard/UserInfoInput";
import { UserDetails } from "../Core/Types";

export default function Dashboard() {
  const store = useContext(StoreContext);
  const [hasMounted, setHasMounted] = useState(false);

  const onUpdate = async ({
    hobbies,
    goals,
    personalityType,
    learningStyle,
    motivation,
    primaryUse,
    moodLevel,
    extra,
  }: UserDetails) => {
    const body = {
      email: store.user?.email,
      userDetails: {
        hobbies,
        goals,
        personalityType,
        learningStyle,
        motivation,
        primaryUse,
        moodLevel,
        extra,
      },
    };
    if (hasMounted) {
      await store.updateDetails(body);
    } else {
      setHasMounted(true);
    }
  };

  const navigate = useNavigate();

  const [debouncedEdit, debouncedState] = useDebounce(onUpdate, 500);

  const textColor = useColorModeValue("navy.700", "gray.300");
  const cardBg = useColorModeValue("white", "gray.800");
  const buttonBorder = useColorModeValue("black", "white");

  const cardBgGradient1 = useColorModeValue(
    "linear(to-r, teal.500, green.500)",
    "linear(to-r, teal.200, blue.500)"
  );
  const cardBgGradient2 = useColorModeValue(
    "linear(to-r, purple.200,purple.100, blue.200 80%)",
    "linear(to-r, purple.800,purple.700, blue.700 80%)"
  );
  const cardBgGradient3 = useColorModeValue(
    "linear(to-r, blue.100, purple.200 80%)",
    "linear(to-r, blue.800, purple.700 80%)"
  );

  const [checkStatState, setCheckStatState] = usePromise();

  useEffect(() => {
    (async () => {
      await setCheckStatState(store.getRoadmapStats());
    })();
  }, []);

  return (
    <Box className="w-11/12 mx-auto">
      <Flex
        direction={"row"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Heading
          fontSize={{ base: "23px", sm: "4xl", md: "5xl" }} // Adjust size as needed
          fontWeight="bold"
          bgGradient="linear(to-r, purple.400, blue.500)"
          bgClip="text"
          mb="3"
        >
          Welcome back, {store.user?.firstName}
        </Heading>
      </Flex>
      <Flex direction={["column", "row"]} mx="auto" gap={3}>
        <Flex direction={"column"} w={["100%", "66.666%"]}>
          <Box position="relative" w="full">
            <UserInfoInput
              debouncedEdit={debouncedEdit}
              debouncedState={debouncedState}
            />
          </Box>
        </Flex>
        <Flex direction={"column"} w={["100%", "33.333%"]} mt={[4, 0]}>
          <Box
            borderWidth="1px"
            borderColor={useColorModeValue("purple.300", "purple.600")}
            ml={[0, 2]}
            p={4}
            borderRadius="6"
            height="100%"
            bgGradient={useColorModeValue(
              "linear(to-r, purple.300, blue.200 95%)",
              "linear(to-r, purple.800, blue.700 95%)"
            )}
          >
            <Stack spacing={"2"}>
              <Box
                borderRadius="md"
                boxShadow="md"
                bg={useColorModeValue("#dcd7fc", "navy.700")}
                border="1px"
                borderColor={useColorModeValue("purple.300", "purple.600")}
                pt={2}
                pl={4}
                height="10%"
              >
                <Stat mb={0}>
                  <StatLabel fontSize="lg">Existing Roadmaps</StatLabel>
                  <StatNumber mb={0}>
                    {checkStatState.loading ? (
                      <Skeleton height="24px" />
                    ) : (
                      store?.userRoadmapStats?.totalRoadmaps?.toLocaleString() ??
                      0
                    )}
                  </StatNumber>
                  <StatHelpText>
                    {checkStatState.loading ? (
                      <Skeleton height="24px" />
                    ) : (
                      <>
                        <StatArrow
                          type={
                            store?.userRoadmapStats?.roadmapGrowth >= 0
                              ? "increase"
                              : "decrease"
                          }
                        />
                        {store?.userRoadmapStats?.roadmapGrowth?.toFixed(2)}%
                        (30 Days)
                      </>
                    )}
                  </StatHelpText>
                </Stat>
              </Box>

              <Box
                borderRadius="md"
                boxShadow="md"
                bg={useColorModeValue("#dcd7fc", "navy.700")}
                border="1px"
                borderColor={useColorModeValue("purple.300", "purple.600")}
                pt={2}
                pl={4}
                height="30%"
              >
                <Stat>
                  <StatLabel fontSize="lg">Total Tasks Created</StatLabel>
                  <StatNumber mb={0}>
                    {checkStatState.loading ? (
                      <Skeleton height="24px" />
                    ) : (
                      store?.userRoadmapStats?.totalTasks?.toLocaleString() ?? 0
                    )}
                  </StatNumber>
                  <StatHelpText>
                    {checkStatState.loading ? (
                      <Skeleton height="24px" />
                    ) : (
                      <>
                        <StatArrow
                          type={
                            store?.userRoadmapStats?.taskGrowth >= 0
                              ? "increase"
                              : "decrease"
                          }
                        />
                        {store?.userRoadmapStats?.taskGrowth?.toFixed(2)}% (30
                        Days)
                      </>
                    )}
                  </StatHelpText>
                </Stat>
              </Box>

              <Box
                borderRadius="md"
                boxShadow="md"
                bg={useColorModeValue("#dcd7fc", "navy.700")}
                border="1px"
                borderColor={useColorModeValue("purple.300", "purple.600")}
                pt={2}
                pl={4}
                height="30%"
              >
                <Stat>
                  <StatLabel fontSize="lg">Materials Collected</StatLabel>
                  <StatNumber mb={0}>
                    {checkStatState.loading ? (
                      <Skeleton height="24px" />
                    ) : (
                      store?.userRoadmapStats?.totalMaterials?.toLocaleString() ??
                      0
                    )}
                  </StatNumber>
                  <StatHelpText>
                    {checkStatState.loading ? (
                      <Skeleton height="24px" />
                    ) : (
                      <>
                        <StatArrow
                          type={
                            store?.userRoadmapStats?.materialGrowth >= 0
                              ? "increase"
                              : "decrease"
                          }
                        />
                        {store?.userRoadmapStats?.materialGrowth?.toFixed(2)}%
                        (30 Days)
                      </>
                    )}
                  </StatHelpText>
                </Stat>
              </Box>
            </Stack>
          </Box>
        </Flex>
      </Flex>

      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
        }}
        gap={6}
        mt={6}
      >
        {" "}
        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          bg={cardBg}
          bgGradient={useColorModeValue(
            "linear(to-r, purple.300, purple.200 80%)",
            "linear(to-r, purple.800, purple.700 80%)"
          )}
          p={6}
          boxShadow="lg"
          height="90%"
        >
          <Heading fontSize="lg" textColor={textColor}>
            Welcome
          </Heading>
          <Text mb={4} textColor={textColor} fontSize="sm">
            Unlock your potential with UpliftMe’s personalized roadmaps,
            tailored to guide you step-by-step towards achieving your goals.
            Discover the best resources and learning plans to become the best
            version of yourself.
          </Text>
        </Box>
        <Box
          borderRadius="lg"
          overflow="hidden"
          borderColor={useColorModeValue("purple.300", "purple.800")}
          borderWidth="5px"
          p={6}
          boxShadow="lg"
          height="90%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Heading fontSize="lg" textColor={textColor}>
            Get Started{" "}
          </Heading>
          <Text mb={4} textColor={textColor} fontSize="sm" textAlign="center">
            Ready to get started? Head over to the Roadmaps page and start
            generating!
          </Text>
          <Button
            onClick={() => navigate("/roadmaps")}
            colorScheme="blue"
            size="md"
            width="75%"
            minH="35%"
            bgGradient={cardBgGradient3}
            position="relative"
            overflow="hidden"
            _before={{
              position: "absolute",
              content: `""`,
              width: "100%",
              height: "100%",
              bgGradient: cardBgGradient2,
              transition: "opacity .7s ease",
            }}
            borderWidth={"1px"}
            borderColor={buttonBorder}
            _hover={{
              _before: { opacity: 0 },
            }}
            mt={2}
          >
            <Box
              position="relative"
              zIndex={1}
              fontSize={"16px"}
              fontWeight={"bold"}
              textColor={textColor}
              px="2"
            >
              Take me there{" "}
              <i className="fa-solid fa-arrow-up-right-from-square ml-1"></i>
            </Box>
          </Button>
        </Box>
        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          bg={cardBg}
          bgGradient={useColorModeValue(
            "linear(to-r, purple.300, blue.200 95%)",
            "linear(to-r, purple.800, blue.700 95%)"
          )}
          p={6}
          boxShadow="lg"
          height="90%"
        >
          <Heading fontSize="lg" textColor={textColor}>
            Whats Next
          </Heading>
          <Text mb={4} textColor={textColor} fontSize="sm">
            Coming soon are tons of more features, including a Marketplace where
            you can post your roadmaps and view other's, complete
            personalization in creating and editing roadmaps, and much more!
          </Text>
        </Box>
      </Grid>
    </Box>
  );
}
