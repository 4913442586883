"use client";
import React, { PropsWithChildren } from "react";

// chakra imports
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  Icon,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
  Button,
} from "@chakra-ui/react";
import Content from "./components/Content";
import { renderThumb, renderTrack, renderView } from "../scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import { isWindowAvailable } from "../../../Utils/navigation";
import { RouteType } from "../../../Core/Types";

export interface SidebarProps extends PropsWithChildren {
  routes: RouteType[];
  [x: string]: any;
}

function Sidebar(props: SidebarProps) {
  const { routes } = props;
  // this is for the rest of the collapses
  let variantChange = "0.2s linear";
  let shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  // Chakra Color Mode
  let sidebarBg = useColorModeValue("white", "navy.800");
  let sidebarRadius = "14px";
  let sidebarMargins = "0px";
  let navbarBg = useColorModeValue(
    "linear(to-b, purple.100, gray.100 6%, gray.100)",
    "linear(to-b, purple.900, navy.900 6%, navy.900)"
  );
  // SIDEBAR
  return (
    <Box display={{ base: "none", xl: "block" }} position="fixed" minH="100%">
      <Box
        boxShadow="lg"
        bg={sidebarBg}
        transition={variantChange}
        w="304px"
        ms={{
          sm: "16px",
        }}
        mb={{
          sm: "16px",
        }}
        h="calc(100vh - 32px)"
        m={sidebarMargins}
        borderBottomLeftRadius={sidebarRadius}
        borderBottomRightRadius={sidebarRadius}
        minH="100%"
        overflowX="hidden"
        bgGradient={navbarBg}
      >
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={renderView}
        >
          <Content routes={routes} />
        </Scrollbars>
      </Box>
    </Box>
  );
}

export function SidebarResponsive(props: { routes: RouteType[] }) {
  let sidebarBackgroundColor = useColorModeValue("white", "navy.800");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { routes } = props;
  return (
    <Flex alignItems="center">
      <Flex w="max-content" h="max-content">
        <Box
          display={{ base: "flex", xl: "none" }}
          position="fixed"
          left={0}
          top="50%"
          transform="translateY(-50%)"
          borderTopRightRadius="md"
          borderBottomRightRadius="md"
          borderTopLeftRadius="0"
          borderBottomLeftRadius="0"
          bgColor="purple.600"
          width="5vw"
          height="9vh"
          minW="50px"
        >
          <Button
            variant="solid"
            colorScheme="purple"
            top="50%"
            transform="translateY(-50%)"
            borderTopRightRadius="md"
            borderBottomRightRadius="md"
            borderTopLeftRadius="0"
            borderBottomLeftRadius="0"
            onClick={onOpen}
            width="90%"
            height="80%"
          >
            <i className="fa-solid fa-bars"></i>
          </Button>
        </Box>
      </Flex>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={
          isWindowAvailable() && document.documentElement.dir === "rtl"
            ? "right"
            : "left"
        }
      >
        <DrawerOverlay />
        <DrawerContent
          w="285px"
          maxW="285px"
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          borderRadius="16px"
          bg={sidebarBackgroundColor}
        >
          <DrawerCloseButton
            zIndex="3"
            onClick={onClose}
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
          />
          <DrawerBody maxW="285px" px="0rem" pb="0">
            <Scrollbars
              autoHide
              renderTrackVertical={renderTrack}
              renderThumbVertical={renderThumb}
              renderView={renderView}
            >
              <Content routes={routes} />
            </Scrollbars>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
// PROPS

export default Sidebar;
