import {
  Box,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Heading,
  Text,
  VStack,
  HStack,
  Image,
  Link,
  Tag,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Flex,
  useDisclosure,
  Badge,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { Handle, Position } from "reactflow";
import { IMaterial, TaskSchema } from "../../Core/Types";
import { HSeparator } from "../HorizonComponents/separator/Separator";
import YouTubeEmbed from "../Misc/YoutubeEmbed";
import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../Contexts/DataStore";
import RoadmapEditModal from "./RoadmapEditModal";
import RoadmapDeleteModal from "./RoadmapDeleteModal";

export function TaskCard({
  data,
}: {
  data: { task: TaskSchema; roadmapId: string };
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const task = data?.task;

  const greenBadge = useColorModeValue("green", "lime");
  const newBadgeColor = useColorModeValue("purple.300", "purple.500");
  const textColor = useColorModeValue("white", "black");
  return (
    <>
      <Handle type="target" position={Position.Left} />

      {task && (
        <Box
          mt={2}
          pointerEvents="auto"
          onClick={(e) => {
            e.stopPropagation();
            onOpen();
          }}
        >
          {task?._id && (
            <>
              <Flex
                position="absolute"
                px="2"
                py="1"
                borderRadius="md"
                textAlign="center"
                justifyContent={"center"}
                justify="center"
                align="center"
                w="1.75vw"
                top="-1.5vh"
                left="95%"
              >
                <RoadmapEditModal
                  task={task}
                  roadmapId={data.roadmapId}
                  size="md"
                  fontSize="xl"
                />
              </Flex>
              <Flex
                position="absolute"
                px="2"
                py="1"
                borderRadius="md"
                textAlign="center"
                justifyContent={"center"}
                justify="center"
                align="center"
                w="1.75vw"
                top="91%"
                left="95%"
              >
                <RoadmapDeleteModal
                  task={task}
                  roadmapId={data.roadmapId}
                  size="md"
                  fontSize="xl"
                ></RoadmapDeleteModal>
              </Flex>
            </>
          )}
          <Card
            size="md"
            w="22.5rem"
            borderRadius="md"
            boxShadow="md"
            borderColor="purple.300"
            className={task.new ? "radiating-purple-border" : ""}
            borderWidth={4}
          >
            <CardHeader p={0}>
              <HStack justifyContent="space-between" p={4}>
                <Heading fontSize={"27"}>{task.type}</Heading>
                {task?.tasks?.length > 0 && (
                  <Badge
                    size="xl"
                    fontSize={"lg"}
                    color={greenBadge}
                    variant="subtle"
                  >
                    {task?.tasks?.length} Subtasks
                  </Badge>
                )}
              </HStack>
            </CardHeader>
            <HSeparator />
            <CardBody>
              <Heading fontSize={"24"} mb="2vh">
                {task.taskName}
              </Heading>
              <Text fontSize="xl" noOfLines={2}>
                {task.overview}
              </Text>
              {task?.materials?.length > 0 && (
                <Button
                  variant="link"
                  colorScheme="blue"
                  onClick={(e) => {
                    e.stopPropagation();
                    onOpen();
                  }}
                  fontSize="xl"
                >
                  ({task.materials.length}) Linked Material
                  {task.materials.length > 1 && "s"}
                </Button>
              )}
            </CardBody>
          </Card>
        </Box>
      )}
      <Handle type="source" position={Position.Right} id="right" />
      <Handle type="source" position={Position.Bottom} id="bottom" />
      <Handle type="source" position={Position.Top} id="top" />

      <TaskDrawer isOpen={isOpen} onClose={onClose} task={task} />
    </>
  );
}

export const TaskDrawer = ({
  isOpen,
  onClose,
  task,
}: {
  isOpen: boolean;
  onClose: () => void;
  task: TaskSchema;
}) => {
  const store = useContext(StoreContext);

  const getCardBody = (type: string, material: IMaterial) => {
    console.log(type);
    switch (type) {
      case "YouTube Videos":
        return <YoutubeCard material={material} />;
      case "Coursera Courses":
        return <WebsiteCard material={material} />;
      case "Books":
        return <BookCard material={material} />;
      case "Websites":
        return <WebsiteCard material={material} />;
      case "Other":
        return <></>;
    }
  };

  useEffect(() => {
    console.log(task.materials);
  }, [isOpen]);

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px" as="h3" fontSize={"2xl"}>
          {task.taskName}
        </DrawerHeader>
        <DrawerBody className="mt-2">
          <VStack spacing={4}>
            <Box width="100%">
              <Heading size="md">Overview</Heading>
              <Card w="full" className="border">
                <CardBody>
                  <VStack align="start" spacing={2}>
                    <Text mb="0" fontWeight="bold">
                      Description:
                    </Text>
                    <Text mt="0">{task.overview || "N/A"}</Text>

                    <HStack alignItems="center">
                      <Text fontWeight="bold">Task Type:</Text>
                      <Badge
                        fontSize="xs"
                        size="xs"
                        variant="outline"
                        colorScheme="blue"
                        mt="-1.5vh"
                        rounded={"10"}
                      >
                        {task.type}
                      </Badge>
                    </HStack>
                    {task?.estimatedTime && (
                      <HStack>
                        <Text fontWeight="bold">Time Estimate:</Text>
                        <Badge
                          fontSize="xs"
                          size="xs"
                          variant="outline"
                          colorScheme="purple"
                          mt="-1.5vh"
                          rounded={"10"}
                        >
                          {task?.estimatedTime?.toString()} Hours
                        </Badge>
                      </HStack>
                    )}
                  </VStack>
                </CardBody>
              </Card>
            </Box>
            <Box w="full">
              <Heading size="md">Materials ({task?.materials?.length})</Heading>
              {task?.materials?.length > 0 ? (
                task?.materials?.map((material, index) => {
                  return <>{getCardBody(material.type, material)}</>;
                })
              ) : (
                <div>
                  <Card className="border ">
                    <CardBody>
                      <Text mt="3">No materials needed for this task.</Text>
                    </CardBody>
                  </Card>
                </div>
              )}
            </Box>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const YoutubeCard = ({ material }: { material: IMaterial }) => {
  const store = useContext(StoreContext);

  return (
    <Card w="full" className="border mb-3">
      <CardHeader mb="0">
        <Text fontWeight={"bold"} as="h5">
          {material.name}
        </Text>
        <HStack spacing={2}>
          <Text fontWeight="bold">Category:</Text>
          <Badge
            fontSize="2xs"
            size="2xs"
            variant="outline"
            colorScheme="red"
            mt="-1.5vh"
            rounded="10"
          >
            {material.type}
          </Badge>
        </HStack>
      </CardHeader>
      <HSeparator mt="-1.5vh" />
      <CardBody>
        <YouTubeEmbed videoId={material?.metadata?.videoId} />
        <Text fontWeight={"bold"} mb="0">
          Why this video?
        </Text>
        <Text>{material.why}</Text>
      </CardBody>
    </Card>
  );
};

const WebsiteCard = ({ material }: { material: IMaterial }) => {
  const result = material.metadata;
  const siteBgCol = useColorModeValue("gray.200", "gray.600");

  const errCol = useColorModeValue("red.600", "red.200");
  return (
    <Card w="full" className="border mb-3">
      <CardHeader mb="0">
        <Text fontWeight={"bold"} as="h5">
          {material.name}
        </Text>
        <HStack spacing={2}>
          <Text fontWeight="bold">Category:</Text>
          <Badge
            fontSize="2xs"
            size="2xs"
            variant="outline"
            colorScheme={material.type === "Websites" ? "purple" : "blue"}
            mt="-1.5vh"
            rounded="10"
          >
            {material.type}
          </Badge>
        </HStack>
      </CardHeader>
      <HSeparator mt="-1.5vh" />
      <CardBody>
        <Box>
          {result?.imageUrl && (
            <Box
              width="100%"
              bg={siteBgCol}
              py={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              rounded="10"
              className="mb-3"
            >
              <Image
                src={result.imageUrl}
                alt={result.title}
                className="w-3/5 rounded-lg "
              />
            </Box>
          )}
          {!result && (
            <Text fontWeight={"bold"} color={errCol}>
              Hmmm... something went wrong in finding this website.{" "}
            </Text>
          )}
          <Box className="font-bold text-xl mb-3">{result?.title}</Box>

          <Text>{result?.snippet}</Text>

          <Text fontWeight={"bold"} mb="0">
            Why this website?
          </Text>
          <Text>{material.why}</Text>

          {result?.link && (
            <Link href={result?.link} color="teal.500" isExternal>
              Visit Site{" "}
              <i className="fa-solid fa-arrow-up-right-from-square ml-1 "></i>
            </Link>
          )}
        </Box>
      </CardBody>
    </Card>
  );
};

const BookCard = ({ material }: { material: IMaterial }) => {
  const [isDescExpanded, setIsDescExpanded] = useState<boolean>(false);

  const bookBgCol = useColorModeValue("gray.200", "gray.600");
  const book = material.metadata;
  return (
    <Card w="full" className="border mb-3">
      <CardHeader mb="0">
        <Text fontWeight={"bold"} as="h5">
          {material.name}
        </Text>
        <HStack spacing={2}>
          <Text fontWeight="bold">Category:</Text>
          <Badge
            fontSize="2xs"
            size="2xs"
            variant="outline"
            colorScheme="blue"
            mt="-1.5vh"
            rounded="10"
          >
            {material.type}
          </Badge>
        </HStack>
      </CardHeader>
      <HSeparator mt="-1.5vh" />
      <Box className=" rounded overflow-hidden shadow-lg p-4 ">
        <Box
          width="100%"
          bg={bookBgCol}
          p={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
          rounded="10"
        >
          <Image
            src={book?.thumbnail}
            alt={book?.title}
            className="w-1/2"
            rounded="5"
          />
        </Box>
        <Box className=" py-4">
          <Box className="font-bold text-xl mb-3">{book?.title}</Box>

          <Box className=" pb-2">
            Author{book?.authors?.length > 1 ? "s" : ""}:{" "}
            {book?.authors?.map((author: any, index: any) => (
              <Badge
                key={index}
                className="inline-block rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2  border ml-1"
              >
                {author}
              </Badge>
            ))}
          </Box>
          <Box className=" mb-4">
            Genre{book?.categories?.length > 1 ? "s" : ""}:{" "}
            {book?.categories?.map((category: any, index: any) => (
              <Badge
                key={index}
                className="inline-block rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2 border mt-1 ml-3"
              >
                {category}
              </Badge>
            ))}
          </Box>
          <Text fontWeight={"bold"} mb="0">
            Description
          </Text>

          <Text
            className=" text-base mb-0"
            fontSize={".9rem"}
            noOfLines={isDescExpanded ? 0 : 8}
          >
            {book?.description}
          </Text>
          <Link
            className="mt-0 text-base"
            color="teal.500"
            onClick={() => setIsDescExpanded(!isDescExpanded)}
          >
            View {isDescExpanded ? "less" : "more"}...
          </Link>
        </Box>

        <Text fontWeight={"bold"} mb="0">
          Why this book?
        </Text>
        <Text>{material.why}</Text>

        <Box className="  pb-2">
          <Link href={book?.canonicalVolumeLink} color="teal.500" isExternal>
            More Info{" "}
            <i className="fa-solid fa-arrow-up-right-from-square ml-1 "></i>
          </Link>
        </Box>
      </Box>
    </Card>
  );
};
