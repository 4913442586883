import { useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  CardHeader,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { StoreContext } from "../Contexts/DataStore";

import { IRoadmap, RoadmapSchema } from "../Core/Types";
import usePromise from "../Hooks/UsePromise";
import { CustomSpinner } from "../Components/Misc/Spinners";
import RoadmapFlow from "../Components/Roadmap/RoadmapFlow";
import {
  Card,
  CardBody,
  CardFooter,
  Image,
  Stack,
  Heading,
  Box,
  Text,
  SimpleGrid,
  GridItem,
  Grid,
} from "@chakra-ui/react";
import { HSeparator } from "../Components/HorizonComponents/separator/Separator";
import { NewRoadmapModal } from "../Components/Modals/NewRoadmapModal";
import { observer } from "mobx-react-lite";
import ListFlow from "../Components/Roadmap/ListFlow";
import useDebounce from "../Hooks/UseDebounce";

export const YourRoadmaps = observer(() => {
  const [currentRoadmap, setRoadmap] = useState<IRoadmap | null>(null);

  const [searchValue, setSearchValue] = useState<string>("");

  const [getRoadmapState, trackRoadmapState] = usePromise("loading");

  const [roadmapView, setRoadmapView] = useState<"Roadmap" | "List">("Roadmap");

  const store = useContext(StoreContext);

  const setCurrentRoadmap = (roadmap: IRoadmap) => {
    window.location.hash = roadmap._id;
    setRoadmap(roadmap);
  };

  console.log(JSON.stringify(currentRoadmap));

  useEffect(() => {
    const updatedRoadmap = store.roadmaps.find(
      (rm: IRoadmap) => rm._id === currentRoadmap?._id
    );
    if (!updatedRoadmap) {
      return;
    }
    setRoadmap(updatedRoadmap);
  }, [store.roadmaps]);

  useEffect(() => {
    (async () => {
      await trackRoadmapState(store.getRoadmaps());
      const hash = window.location.hash
        ? window.location.hash.substring(1)
        : null;
      if (hash) {
        const roadmap = store.roadmaps.find((roadmap) => roadmap._id === hash);
        roadmap ? setCurrentRoadmap(roadmap) : (window.location.hash = "");
      }
    })();
  }, []);

  const borderColor = useColorModeValue("purple.600", "purple.200");

  const textColor = useColorModeValue("white", "black");

  const invTextColor = useColorModeValue("black", "white");

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div className="w-11/12  mx-auto">
      {!currentRoadmap ? (
        <>
          <div className="d-flex justify-content-center mb-3">
            <NewRoadmapModal
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
            />
            <Input
              placeholder="Search"
              size="md"
              className="ml-1 w-75"
              borderColor={borderColor}
              borderRadius="md"
              textColor={invTextColor}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Button
              size="md"
              colorScheme="purple"
              className="ml-2"
              onClick={() => {}}
              borderRadius="md"
              variant="outline"
            >
              <i className="fa-solid fa-magnifying-glass "></i>
            </Button>
          </div>

          {getRoadmapState.loading && store.roadmaps?.length <= 0 ? (
            <div className="flex justify-center items-center h-50">
              {getRoadmapState.loading && <CustomSpinner size={8} />}
            </div>
          ) : (
            <div className="overflow-y-auto">
              {store.roadmaps.length > 0 ? (
                <SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} spacing={3}>
                  {store.roadmaps
                    .filter(
                      (roadmap) =>
                        searchValue === "" ||
                        roadmap?.roadmapName
                          ?.toLowerCase()
                          ?.includes(searchValue.toLowerCase()) ||
                        roadmap?.description
                          ?.toLowerCase()
                          ?.includes(searchValue.toLowerCase())
                    )
                    .map((roadmap) => (
                      <RoadmapCard
                        key={roadmap._id} // Added key prop
                        id={roadmap._id}
                        roadmap={roadmap}
                        setCurrentRoadmap={setCurrentRoadmap}
                      />
                    ))}
                </SimpleGrid>
              ) : (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Card
                    align="center"
                    mt={{ base: "4vh", md: "8vh" }} // Adjusted margin-top for mobile
                    width={{ base: "80vw", lg: "40vw" }} // Adjusted width for mobile
                    borderColor="purple.500"
                    borderWidth="2px"
                    borderStyle="solid"
                  >
                    <Heading size="md" mt="2vh" mb="2vh">
                      You have no active roadmaps
                    </Heading>
                    <HSeparator />
                    <CardFooter>
                      <Button
                        colorScheme="blue"
                        size="md"
                        width="full"
                        bgGradient="linear(to-r, blue.400, purple.500 80%)"
                        position="relative"
                        overflow="hidden"
                        _before={{
                          position: "absolute",
                          content: `""`,
                          width: "100%",
                          height: "100%",
                          bgGradient: "linear(to-r, purple.500, blue.400 80%)",
                          transition: "opacity .7s ease",
                        }}
                        _hover={{
                          _before: { opacity: 0 },
                        }}
                        mt={2} // Replaced className with Chakra UI prop
                        onClick={onOpen}
                      >
                        <Box
                          position="relative"
                          zIndex={1}
                          color={textColor} // Ensure the text color is white or appropriate for the background
                        >
                          Get Started!
                        </Box>
                      </Button>
                    </CardFooter>
                  </Card>
                </Box>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <Toolbar
            roadmapView={roadmapView}
            setRoadmap={setRoadmap}
            setRoadmapView={setRoadmapView}
          />

          {roadmapView === "Roadmap" ? (
            <RoadmapFlow roadMapData={currentRoadmap} />
          ) : (
            <ListFlow roadMapData={currentRoadmap} />
          )}
        </>
      )}
    </div>
  );
});

const RoadmapCard = ({
  id,
  roadmap,
  setCurrentRoadmap,
}: {
  id: string;
  roadmap: IRoadmap;
  setCurrentRoadmap: (roadmap: IRoadmap) => void;
}) => {
  const recCountNodes = (
    task: any,
    milestoneCount = 0,
    taskCount = 0,
    materialCount = 0
  ) => {
    task.tasks?.forEach((subTask: any) => {
      if (subTask?.type === "Milestone") {
        milestoneCount++;
      } else {
        taskCount++;
      }
      materialCount += subTask?.materials?.length ?? 0;
      if (subTask?.tasks?.length > 0) {
        const result = recCountNodes(
          subTask,
          milestoneCount,
          taskCount,
          materialCount
        );
        milestoneCount = result.milestoneCount;
        taskCount = result.taskCount;
        materialCount = result.materialCount;
      }
    });

    return { milestoneCount, taskCount, materialCount };
  };

  const { milestoneCount, taskCount, materialCount } = recCountNodes(roadmap);

  const [getDeleteState, trackDeleteState] = usePromise();
  const store = useContext(StoreContext);
  const borderColor = useColorModeValue("purple.600", "purple.200");
  const newBadgeColor = useColorModeValue(
    "linear-gradient(to right, purple.300, transparent)",
    "linear-gradient(to right, purple.500, transparent)"
  );
  const spinnerColor = useColorModeValue("white", "purple.100");

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [newRoadmapName, setRoadmapName] = useState<string>(
    roadmap.roadmapName
  );

  const toast = useToast();
  const deleteRoadmap = async () => {
    try {
      await trackDeleteState(store.deleteRoadmap({ roadmapId: roadmap._id }));
      toast({
        title: "Roadmap Successfully Deleted",
        status: "success",
        duration: 2500,
        isClosable: true,
        position: "top-right",
      });
    } catch (error: any) {
      toast({
        title: "Roadmap Deletion Failed",
        description: "Something went wrong. Failed to delete roadmap.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const editRoadmap = async (name: string) => {
    const body = { roadmapId: id, roadmapName: name };
    const res = await store.editRoadmap(body);
    if (res.status !== 200) {
      throw new Error("Failed to update roadmap");
    }
  };

  const [debouncedEdit, debouncedState] = useDebounce(editRoadmap, 500);

  const handleChange = (e: any) => {
    setRoadmapName(e.target.value);
    debouncedEdit(e.target.value);
  };

  const textColor = useColorModeValue("black", "white");

  const gradient = useColorModeValue(
    "linear(to-r, white, #f0f2f7)",
    "linear(to-r, #1a202c, #242c36)"
  );

  return (
    <Card
      direction={{ base: "column", sm: "row" }}
      overflow="visible"
      variant="outline"
      borderColor={borderColor}
      position="relative"
      bgGradient={gradient}
    >
      {roadmap?.new && (
        <Flex
          position="relative"
          bgGradient={newBadgeColor}
          borderRadius="md"
          textAlign="center"
          justifyContent={"center"}
          justify="center"
          align="center"
          w="1vw"
        ></Flex>
      )}
      <Stack w="full">
        <CardBody display="flex" flexDirection="column" mt={0} pt={3}>
          <Flex justifyContent="space-between" alignItems="center" mb={2}>
            <Box display="flex" alignItems="center" width="100%">
              {isEditing ? (
                <InputGroup size="md" flex="1">
                  <Input
                    value={newRoadmapName}
                    onChange={handleChange}
                    onBlur={() => setIsEditing(false)}
                    color={textColor}
                    autoFocus
                    pr="4.5rem" // Ensure space for the icon
                    focusBorderColor={borderColor}
                    fontWeight={"bold"}
                    fontSize="lg"
                    borderWidth={0.5}
                  />
                  <InputRightElement width="4.5rem">
                    {debouncedState.loading && (
                      <Spinner
                        size="sm"
                        speed=".8s"
                        emptyColor={spinnerColor}
                        color="purple.700"
                      />
                    )}
                    {debouncedState.done && (
                      <Box
                        w="2rem"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <i className="fa-solid fa-check text-green-700"></i>
                      </Box>
                    )}
                    {debouncedState.error && (
                      <Box
                        w="2rem"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <i className="fa-solid fa-x text-red-700"></i>
                      </Box>
                    )}
                  </InputRightElement>
                </InputGroup>
              ) : (
                <Box display="flex" alignItems="center" flex="1">
                  <Heading
                    size="md"
                    noOfLines={1}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "none",
                      _hover: {
                        textDecoration: "underline",
                      },
                      flex: "1",
                    }}
                    onClick={() => setCurrentRoadmap(roadmap)}
                    mb={0}
                  >
                    {newRoadmapName}
                  </Heading>
                  <Button variant="ghost" onClick={() => setIsEditing(true)}>
                    <i className="fa-solid fa-pen-to-square"></i>
                  </Button>
                </Box>
              )}
            </Box>
          </Flex>
          <HSeparator />

          <Text fontSize="sm" mt="2" noOfLines={4} className="mb-0">
            {roadmap?.description ?? "No description available."}
          </Text>
          <Flex mt="auto" pt="4" mb="0">
            <Tooltip
              label="Number of tasks in your roadmap"
              aria-label="Tasks Tooltip"
            >
              <Badge variant="outline" colorScheme="purple" size="sm">
                {milestoneCount + taskCount} Tasks
              </Badge>
            </Tooltip>
            <Tooltip
              label="Number of materials linked in your roadmap"
              aria-label="Materials Tooltip"
            >
              <Badge
                variant="outline"
                colorScheme="purple"
                size="sm"
                className="ml-1"
              >
                {materialCount} Materials
              </Badge>
            </Tooltip>
          </Flex>
        </CardBody>

        <div className="display-flex mt-0">
          <HSeparator />

          <CardFooter className="d-flex justify-content-between align-items-center mt-0">
            <div>
              <Button
                variant="outline"
                colorScheme="purple"
                onClick={() => setCurrentRoadmap(roadmap)}
                size="sm"
                borderRadius="md"
              >
                View <i className="fa-solid fa-book-open-reader ml-2"></i>
              </Button>
            </div>
            <Button
              variant="outline"
              colorScheme="red"
              onClick={() => deleteRoadmap()}
              size="sm"
              borderRadius="md"
              className="ml-auto"
              isLoading={getDeleteState.loading}
            >
              <i className="fa-regular fa-trash-can"> </i>
            </Button>
          </CardFooter>
        </div>
      </Stack>
    </Card>
  );
};

const Toolbar = ({ roadmapView, setRoadmap, setRoadmapView }: any) => {
  return (
    <Box p={2} borderRadius="md" borderWidth="2px" mb={1}>
      <Grid templateColumns="1fr auto" gap={4}>
        <GridItem display="flex" alignItems="center">
          <Button
            size="sm"
            colorScheme="purple"
            variant="link"
            onClick={() => {
              setRoadmap(null);
              window.location.hash = "";
            }}
            className="ml-2"
          >
            <i className="mr-2 fa-solid fa-arrow-left"></i>Back to Roadmaps
          </Button>
        </GridItem>
        <GridItem display="flex" justifyContent="flex-end">
          <Button
            size="sm"
            colorScheme={roadmapView === "Roadmap" ? "purple" : "gray"}
            variant={roadmapView === "Roadmap" ? "solid" : "outline"}
            rounded="md"
            disabled={roadmapView === "List"}
            onClickCapture={() => {
              setRoadmapView("Roadmap");
            }}
            sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          >
            <Box display={{ base: "none", md: "inline" }} mr={2}>
              Roadmap View
            </Box>
            <i className="fa-solid fa-road"></i>
          </Button>
          <Button
            size="sm"
            colorScheme={roadmapView === "List" ? "purple" : "gray"}
            variant={roadmapView === "List" ? "solid" : "outline"}
            disabled={roadmapView === "Roadmap"}
            rounded="md"
            onClickCapture={() => {
              setRoadmapView("List");
            }}
            sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          >
            <Box display={{ base: "none", md: "inline" }} mr={2}>
              List View
            </Box>
            <i className="fa-solid fa-list"></i>
          </Button>
        </GridItem>
      </Grid>
    </Box>
  );
};
