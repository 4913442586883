"use client";
import React, { ReactNode } from "react";
import {
  ChakraProvider,
  Box,
  Portal,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import theme from "../../theme/theme";

import { useEffect, useState } from "react";
import "../../Styles/App.css";
import "../../Styles/Contact.css";
import "../../Styles/Plugins.css";
import "../../Styles/MiniCalendar.css";
import Sidebar from "./sidebar/Sidebar";
import { protectedRoutes } from "../../Routes/Routes";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "./footer/FooterAdmin";
import AdminNavbar from "./navbar/NavbarAdmin";

export default function RootLayout({
  children,
  rootName,
}: {
  children: ReactNode;
  rootName: string;
}) {
  const pathname = useLocation().pathname;

  const routes = protectedRoutes;

  return (
    <>
      <Flex direction="column" minHeight="100vh">
        <Box>
          <AdminNavbar
            secondary={false}
            brandText={rootName}
            logoText={rootName}
            onOpen={() => {}}
            setApiKey={() => {}}
          />
          <Sidebar routes={routes} />
          <Box
            pt={{ base: "100px", md: "100px" }}
            float="right"
            minHeight="100vh"
            height="100%"
            overflow="auto"
            position="relative"
            maxHeight="100%"
            w={{ base: "100%", xl: "calc( 100% - 290px )" }}
            maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
          >
            <Box mx="auto" p={{ base: "20px", md: "30px" }} pe="20px">
              {children}
            </Box>

            {/* <Footer /> */}
          </Box>
        </Box>
      </Flex>
    </>
  );
}
