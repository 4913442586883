"use client";
// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";
import { HSeparator } from "../../separator/Separator";
import { UpliftMeLogo } from "../../icons/Icons";

export function SidebarBrand() {
  //   Chakra color mode

  return (
    <Flex alignItems="center" flexDirection="column">
      <UpliftMeLogo />
      <HSeparator mb="20px" w="380px" />
    </Flex>
  );
}

export default SidebarBrand;
