import React from "react";
import { FidgetSpinner, ProgressBar, TailSpin } from "react-loader-spinner";
import { COLORS } from "../../Constants/MiscConstants";

type SpinnerType = "fidgetSpinner" | "tailSpin" | "progressBar";

interface SpinnerProps {
  type?: SpinnerType;
  size: number;
}

export const CustomSpinner = ({ type, size }: SpinnerProps) => {
  const spinnerDimensions = (size * 10).toString();
  switch (type) {
    case "fidgetSpinner":
      return (
        <FidgetSpinner
          visible={true}
          height={spinnerDimensions}
          width={spinnerDimensions}
          ariaLabel="fidget-spinner-loading"
          backgroundColor={COLORS.PRIMARY}
          wrapperStyle={{}}
          wrapperClass="fidget-spinner-wrapper"
        />
      );
    case "tailSpin":
      return (
        <TailSpin
          visible={true}
          height={spinnerDimensions}
          width={spinnerDimensions}
          color={COLORS.PRIMARY}
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
        />
      );
    case "progressBar":
      return (
        <ProgressBar
          visible={true}
          height={spinnerDimensions}
          width={spinnerDimensions}
          barColor="#a9c6e3"
          borderColor="#1a9bff"
          // color="#4fa94d"
          ariaLabel="progress-bar-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      );
    default:
      return (
        <TailSpin
          visible={true}
          height={spinnerDimensions}
          width={spinnerDimensions}
          color="#00719c"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
        />
      );
  }
};
