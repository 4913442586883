import React, { useState, useContext } from "react";
import { useForm, FieldValues } from "react-hook-form";
import {
  Box,
  Button,
  Flex,
  FormControl,
  HStack,
  PinInput,
  PinInputField,
  Stack,
  Text,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { StoreContext } from "../Contexts/DataStore";
import { successToast } from "../Components/Misc/Toasts";

const EnterPin = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [generalError, setGeneralError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const email = location?.state?.email; // Get email passed from ResetPassword
  const store = useContext(StoreContext);

  const inputTextColor = useColorModeValue("gray.800", "gray.200");
  const boxCol = useColorModeValue("white", "gray.700");
  const pageBg = useColorModeValue(
    "linear(to-b, purple.100, gray.100 50%, gray.100)",
    "linear(to-b, purple.900, navy.900 60%, navy.900)"
  );
  const submitBg = useColorModeValue("purple.400", "purple.600");

  const submitPin = async (data: FieldValues) => {
    const result = await store.verifyResetPin({ ...data, email });
    if (result?.status !== 200) {
      setGeneralError(
        result?.data?.message ?? "Invalid PIN. Please try again."
      );
    } else {
      successToast("Pin successfully verified.");
      navigate("/resetpassword", { state: { email, pin: data?.pin } });
    }
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bgGradient={pageBg}
      p={4}
    >
      <Stack
        spacing={4}
        mx={"auto"}
        maxW={"lg"}
        py={12}
        px={6}
        width={{ base: "90vw", md: "70vw", lg: "50vw" }}
      >
        <Stack align={"center"}>
          <Text
            fontSize={{ base: "2xl", md: "4xl" }}
            fontWeight={"bold"}
            mb="0"
          >
            Enter Email PIN
          </Text>
        </Stack>
        <Box rounded={"lg"} bg={boxCol} boxShadow={"xl"} p={8}>
          <form onSubmit={handleSubmit(submitPin)}>
            <Stack spacing={4}>
              <FormControl id="pin">
                <Flex justifyContent="center">
                  <HStack>
                    <PinInput
                      onChange={(value) => setValue("pin", value)}
                      colorScheme="blue"
                      size="lg"
                    >
                      <PinInputField color={inputTextColor} />
                      <PinInputField color={inputTextColor} />
                      <PinInputField color={inputTextColor} />
                      <PinInputField color={inputTextColor} />
                      <PinInputField color={inputTextColor} />
                      <PinInputField color={inputTextColor} />
                    </PinInput>
                  </HStack>
                </Flex>
                {errors.pin && <Text color="red.500">PIN is required.</Text>}
              </FormControl>
              <Button
                type="submit"
                colorScheme="blue"
                width="full"
                bgColor={submitBg}
                color={"white"}
              >
                Verify PIN
              </Button>
              {generalError && (
                <Text color="red.500" textAlign="center">
                  {generalError}
                </Text>
              )}
              <Stack pt={2}>
                <Text
                  align={"center"}
                  onClick={() => navigate("/login")}
                  mb={0}
                >
                  <Link color={"blue.400"}>
                    <i className="fa-solid fa-arrow-left"></i> Back to Log in
                  </Link>
                </Text>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export default EnterPin;
