import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import React, { memo } from "react";
import { Handle, useStore, Position } from "reactflow";

export default memo(({ data, id }: any) => {
  const size = "200px";
  const message = data.message;

  const bgGradent = useColorModeValue(
    "linear(to-r, blue.300 60%, purple.400)",
    "linear(to-r, blue.700 60%, purple.700)"
  );

  const txtColor = useColorModeValue("black", "white");

  return (
    <>
      <Box
        className="wrapper"
        bgGradient={bgGradent}
        p="4"
        borderRadius="full"
        boxShadow="lg"
        textAlign="center"
        color="white"
        fontWeight="bold"
        w={size}
        h={size}
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderWidth={5}
        borderColor="purple"
      >
        <Text fontSize="45px" mt="3" fontWeight={"bold"} textColor={txtColor}>
          {message}
        </Text>
      </Box>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#555" }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{ background: "#555" }}
      />
    </>
  );
});
