import { useState } from "react";

type PromiseState = {
  data: any;
  error: any;
  loading: boolean;
  failed: boolean;
  success: boolean;
};

const usePromise = (
  defualt?: string
): [PromiseState, (asyncFunc: Promise<any>) => Promise<any>, () => void] => {
  const [promiseState, setPromiseState] = useState<PromiseState>({
    data: null,
    error: null,
    loading: defualt === "loading",
    failed: defualt === "failed",
    success: defualt === "success",
  });

  const trackState = async (asyncFunc: Promise<any>) => {
    try {
      setPromiseState({
        ...promiseState,
        loading: true,
      });
      const result = await asyncFunc;
      setPromiseState({
        data: result,
        error: null,
        loading: false,
        failed: false,
        success: true,
      });
      return result;
    } catch (error) {
      setPromiseState({
        ...promiseState,
        error: error,
        loading: false,
        failed: true,
        success: false,
      });
      return error;
    }
  };

  const resetState = () => {
    setPromiseState({
      data: null,
      error: null,
      loading: false,
      failed: false,
      success: false,
    });
  };

  return [promiseState, trackState, resetState];
};

export default usePromise;
