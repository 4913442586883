import React from "react";
import { Box } from "@chakra-ui/react";

const YouTubeEmbed = ({ videoId }: { videoId: string }) => {
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <Box
      position="relative"
      paddingBottom="56.25%"
      height="0"
      overflow="hidden"
      maxWidth="100%"
      bg="black"
      mb={4}
      rounded={"10px"}
    >
      <Box
        as="iframe"
        src={embedUrl}
        frameBorder="0"
        allowFullScreen
        title="YouTube Video"
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
      />
    </Box>
  );
};

export default YouTubeEmbed;
