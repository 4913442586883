import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import React, { memo } from "react";
import { Handle, useStore, Position } from "reactflow";

export default memo(({ data }: any) => {
  const size = "200px";

  const bgGradient = useColorModeValue(
    "radial-gradient(circle at center, white 60%, black 10%)",
    "radial-gradient(circle at center, black 67.5%, white 5%)"
  );

  const txtColor = useColorModeValue("black", "white");

  return (
    <>
      <Box
        className="wrapper"
        bg={bgGradient}
        p="4"
        borderRadius="full"
        boxShadow="lg"
        textAlign="center"
        color="white"
        fontWeight="bold"
        w={size}
        h={size}
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderWidth={5}
        borderColor="white"
      >
        <Text fontSize="100px" mt="3" fontWeight={"bold"} textColor={txtColor}>
          ʕ•ᴥ•ʔ
        </Text>
      </Box>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#555" }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{ background: "#555" }}
      />
    </>
  );
});
