import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  useColorModeValue,
  FormControl,
  VStack,
  Container,
  Heading,
  Input,
  Flex,
  HStack,
  Checkbox,
  Kbd,
  Box,
  Badge,
  useSteps,
  CheckboxGroup,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  StepTitle,
  StepSeparator,
  useToast,
} from "@chakra-ui/react";
import { HSeparator } from "../HorizonComponents/separator/Separator";
import { StoreContext } from "../../Contexts/DataStore";
import { TaskSchema } from "../../Core/Types";
import { errorToast, successToast } from "../Misc/Toasts";
import usePromise from "../../Hooks/UsePromise";

const RoadmapDeleteModal = ({
  task,
  roadmapId,
  size,
  fontSize,
}: {
  task: TaskSchema;
  roadmapId: string;
  size: string;
  fontSize: string;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const newBadgeColor = useColorModeValue("purple.400", "purple.300");
  const textColor = useColorModeValue("white", "black");
  const inputColor = useColorModeValue("navy.700", "white");
  const [trackDeletePromise, trapDeletePromise] = usePromise();

  const isDisabled = false; //this is a premium feature!

  const store = useContext(StoreContext);
  const toast = useToast();

  const generateRoadmap = async () => {
    try {
      const data = { roadmapId, taskId: task._id };
      const promise = await trapDeletePromise(store.deleteRoadmapTask(data));
      onClose();
      successToast("Task deleted successfully");
    } catch (error) {
      errorToast("Task deleted successfully");
    }
  };

  return (
    <>
      <Button
        fontWeight="bold"
        color={textColor}
        fontSize="md"
        letterSpacing={1}
        bgColor={newBadgeColor}
        zIndex={100}
        onClick={(e) => {
          e.stopPropagation();
          onOpen();
        }}
        size={size}
      >
        <i className={`fa-solid fa-trash text-${fontSize}`}></i>
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={"24"}>Confirm Delete</ModalHeader>
          <HSeparator />
          <ModalBody>
            <Text fontSize={"18"}>
              Are you sure you want to delete this task
              {task?.tasks?.length > 0
                ? ` and its ${task?.tasks?.length} subtasks?`
                : "?"}
            </Text>
          </ModalBody>
          <HSeparator />
          <ModalFooter>
            <Button
              colorScheme="red"
              isLoading={trackDeletePromise.loading}
              rounded={"md"}
              onClick={generateRoadmap}
              size="md"
            >
              Delete
            </Button>
            <Button
              colorScheme="purple"
              variant="outline"
              rounded={"md"}
              onClick={onClose}
              ml={3}
              size="md"
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RoadmapDeleteModal;
