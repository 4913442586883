export const COLORS = {
  PRIMARY: "#00719c",
  SECONDARY: "#009bd6",
  LIGHT: "#00b8ff",
  DARK: "#00415a",
  TEXT: "#001f2b",
};

export const COMPANY_LOGO = "transparent_logo.png";
export const DEFAULT_USER_ICON = "defaultusericon.png";
export const COMPANY_NAME = "UpliftMe";

export const GEN_LIMIT = 10;
