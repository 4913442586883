import {
  Box,
  Input,
  Textarea,
  Select,
  VStack,
  Button,
  useColorModeValue,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useDisclosure,
  DrawerBody,
  Text,
  DrawerHeader,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Drawer,
  DrawerFooter,
  Flex,
  Heading,
  useColorMode,
  Image,
  Spinner,
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import Transparent_Logo from "../../img/logos/transparent_logo_icon.png";

import CryingFace from "../../img/emojis/crying-face_1f622.png";
import MonocleFace from "../../img/emojis/face-with-monocle_1f9d0.png";
import GrinningFace from "../../img/emojis/grinning-face-with-smiling-eyes_1f604.png";
import NerdFace from "../../img/emojis/nerd-face_1f913.png";
import NeutralFace from "../../img/emojis/neutral-face_1f610.png";
import SleepingFace from "../../img/emojis/slightly-smiling-face_1f642.png";
import SlightSmile from "../../img/emojis/sleeping-face_1f634.png";
import StarStruck from "../../img/emojis/star-struck_1f929.png";

import { StoreContext } from "../../Contexts/DataStore";

const UserInfoInput = ({
  debouncedEdit,
  debouncedState,
}: {
  debouncedEdit: any;
  debouncedState: any;
}) => {
  const store = useContext(StoreContext);

  const [extra, setExtra] = useState(store?.user?.userDetails?.extra || "");
  const [motivation, setMotivation] = useState(
    store?.user?.userDetails?.motivation || ""
  );
  const [personalityType, setPersonalityType] = useState(
    store?.user?.userDetails?.personalityType || ""
  );
  const [hobbies, setHobbies] = useState(
    store?.user?.userDetails?.hobbies || ""
  );
  const [goals, setGoals] = useState(store?.user?.userDetails?.goals || "");
  const [learningStyle, setLearningStyle] = useState(
    store?.user?.userDetails?.learningStyle || ""
  );
  const [primaryUse, setPrimaryUse] = useState(
    store?.user?.userDetails?.primaryUse || ""
  );
  const [moodLevel, setMoodLevel] = useState(
    store?.user?.userDetails?.moodLevel || 30
  );

  const btnRef = useRef<any>();

  const handleSubmit = () => {
    debouncedEdit({
      hobbies,
      goals,
      personalityType,
      learningStyle,
      motivation,
      primaryUse,
      moodLevel,
      extra,
    });
  };

  useEffect(() => {
    handleSubmit();
  }, [
    extra,
    motivation,
    personalityType,
    hobbies,
    goals,
    learningStyle,
    primaryUse,
    moodLevel,
  ]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const getEmoji = (value: number) => {
    if (value < 10) return <Image src={SleepingFace} alt="😴" />;
    if (value < 20) return <Image src={CryingFace} alt="😢" />;
    if (value < 30) return <Image src={NeutralFace} alt="😐" />;
    if (value < 50) return <Image src={SlightSmile} alt="🙂" />;
    if (value < 65) return <Image src={GrinningFace} alt="😄" />;
    if (value < 80) return <Image src={NerdFace} alt="🤓" />;
    if (value < 90) return <Image src={MonocleFace} alt="🧐" />;
    return <Image src={StarStruck} alt="🤩" />;
  };

  const cardBgGradient2 = useColorModeValue(
    "linear(to-r, purple.200,purple.100, blue.200 80%)",
    "linear(to-r, purple.800,purple.700, blue.700 80%)"
  );
  const cardBgGradient3 = useColorModeValue(
    "linear(to-r, blue.100, purple.200 80%)",
    "linear(to-r, blue.800, purple.700 80%)"
  );

  const borderColor = useColorModeValue("purple.300", "purple.400");
  const textColor = useColorModeValue("navy.700", "gray.300");
  const cardBg = useColorModeValue("white", "gray.800");
  const buttonBorder = useColorModeValue("black", "white");
  const spinnerColor = useColorModeValue("white", "purple.100");

  return (
    <Box
      borderWidth="1px"
      borderColor={useColorModeValue("purple.300", "purple.600")}
      p={6}
      borderRadius="md"
    >
      <VStack spacing={4} align="stretch">
        <Textarea
          placeholder="Your Hobbies (e.g., reading, hiking, coding)"
          value={hobbies}
          onChange={(e) => setHobbies(e.target.value)}
          size="md"
          height={100}
          borderColor={useColorModeValue("purple.300", "purple.600")}
        />
        <Textarea
          placeholder="Your Goals (e.g., I want to learn AI, I want to improve my coding skills)"
          value={goals}
          onChange={(e) => setGoals(e.target.value)}
          size="md"
          height={100}
          borderColor={useColorModeValue("purple.300", "purple.600")}
        />
        <Flex direction={{ base: "column", sm: "row" }}>
          <Text
            fontSize="xl"
            w={{ base: "100%", sm: "37%" }}
            mb={0}
            mt={1}
            fontWeight={"bold"}
            textColor={useColorModeValue("blue.600", "blue.400")}
          >
            Mood Meter <i className="fa-solid fa-spa text-xl ml-2"></i>
          </Text>
          <Slider
            aria-label="mood-slider"
            defaultValue={30}
            value={moodLevel}
            onChange={(num) => setMoodLevel(num)}
            height={10}
          >
            <SliderTrack
              bgGradient={`linear(to-r, blue.500,purple.500,purple.400  80%)`} // Dark blue and purple gradient
            >
              <SliderFilledTrack bg="transparent" />
            </SliderTrack>
            <SliderThumb boxSize={12} bg="orange.100">
              <Box fontSize={{ base: "30px", sm: "42px" }}>
                {getEmoji(moodLevel)}
              </Box>{" "}
              {/* Emoji changes based on slider level */}
            </SliderThumb>
          </Slider>
        </Flex>
        {/* <Flex width="100%" justifyContent="space-between" alignItems="center"> */}
        <Button
          ref={btnRef}
          colorScheme="blue"
          onClick={onOpen}
          size="md"
          minH="35%"
          bgGradient={cardBgGradient3}
          position="relative"
          overflow="hidden"
          _before={{
            position: "absolute",
            content: `""`,
            width: "100%",
            height: "100%",
            bgGradient: cardBgGradient2,
            transition: "opacity .7s ease",
          }}
          borderWidth={"1px"}
          borderColor={buttonBorder}
          _hover={{
            _before: { opacity: 0 },
          }}
          mt={2}
          borderRadius="lg"
          width="full"
          mr="3"
        >
          <Box
            position="relative"
            zIndex={1}
            fontSize={{ base: "14px", sm: "16px" }}
            fontWeight={"bold"}
            textColor={textColor}
            px="2"
          >
            Advanced Details
            <i className="fa-solid fa-wand-magic-sparkles ml-2 "></i>
          </Box>
        </Button>
        {/* <Button
            borderRadius="lg"
            variant="outline"
            size="md"
            mt={2}
            borderColor={buttonBorder}
            width="10%"
          >
            {debouncedState.loading && (
              <Spinner
                size="md"
                speed=".8s"
                emptyColor={spinnerColor}
                color="purple.700"
                mb="1"
              />
            )}
            {debouncedState.done && (
              <Box
                w="1rem"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <i className="fa-solid fa-check text-green-600 text-2xl"></i>
              </Box>
            )}
            {debouncedState.error && (
              <Box
                w="1rem"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <i className="fa-solid fa-x text-red-600 text-2xl"></i>
              </Box>
            )}
          </Button> 
        </Flex>*/}
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
          size="sm"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              <Heading
                textColor={useColorModeValue("purple.700", "purple.400")}
              >
                {" "}
                Advanced Details
              </Heading>{" "}
            </DrawerHeader>

            <DrawerBody>
              <VStack spacing={4} align="stretch">
                <Select
                  placeholder="Preferred Learning Style"
                  value={learningStyle}
                  onChange={(e) => setLearningStyle(e.target.value)}
                  borderColor={borderColor}
                >
                  <option value="visual">Visual</option>
                  <option value="auditory">Auditory</option>
                  <option value="kinesthetic">Kinesthetic</option>
                  <option value="reading/writing">Reading/Writing</option>
                </Select>
                <Select
                  placeholder="Current Motivation level"
                  value={motivation}
                  onChange={(e) => setMotivation(e.target.value)}
                  borderColor={borderColor}
                >
                  <option value="motivated">Ultra High</option>
                  <option value="curious">High</option>
                  <option value="relaxed">Average</option>
                  <option value="stressed">Low</option>
                </Select>
                <Select
                  placeholder="Personality Type"
                  value={personalityType}
                  onChange={(e) => setPersonalityType(e.target.value)}
                  borderColor={borderColor}
                >
                  <option value="INTJ">INTJ</option>
                  <option value="INFJ">INFJ</option>
                  <option value="ENFP">ENFP</option>
                  <option value="ENTP">ENTP</option>
                  <option value="ISTJ">ISTJ</option>
                  <option value="ISFJ">ISFJ</option>
                  <option value="ESTP">ESTP</option>
                  <option value="ESFP">ESFP</option>
                </Select>
                <Select
                  placeholder="Primary Use of App"
                  value={primaryUse}
                  onChange={(e) => setPrimaryUse(e.target.value)}
                  borderColor={borderColor}
                >
                  <option value="personal development">
                    Personal Development
                  </option>
                  <option value="school topics">School Topics</option>
                  <option value="career">Career</option>
                  <option value="learning for fun">Learning for Fun</option>
                </Select>
                <Textarea
                  placeholder="Anything else you want to add?"
                  value={extra}
                  onChange={(e) => setExtra(e.target.value)}
                  size="md"
                  height={100}
                  borderColor={borderColor}
                />
              </VStack>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="50%"
                ml="3"
              >
                <Image
                  src={Transparent_Logo}
                  alt="Logo"
                  opacity={0.45} // Adjust this value for the desired fade effect
                />
              </Box>
            </DrawerBody>

            <DrawerFooter></DrawerFooter>
          </DrawerContent>
        </Drawer>
      </VStack>
    </Box>
  );
};

export default UserInfoInput;
