import { Route } from "react-router-dom";
import { RouteType } from "../Core/Types";
import ChatUI from "../Pages/ChatUI";
import LogInCard from "../Pages/LogInCard";
import SignupCard from "../Pages/SignUpCard";
import Dashboard from "../Pages/Dashboard";
import Marketplace from "../Pages/MarketPlace";
import { YourRoadmaps } from "../Pages/YourRoadmaps";
import { ResetPassword } from "../Pages/ResetPasswordEmail";
import EnterPin from "../Pages/EnterPasswordPin";
import ResetPasswordForm from "../Pages/ResetPasswordForm";

// root routes

const LogIn: RouteType = {
  path: "/login",
  name: "Log In",
  exact: true,
  icon: <i className="fa-solid fa-robot"></i>,
  component: LogInCard,
  route: Route,
  isAuthRoute: true,
};

const SignUp: RouteType = {
  path: "/signup",
  name: "Sign Up",
  exact: true,
  icon: <i className="fa-solid fa-robot"></i>,
  component: SignupCard,
  route: Route,
  isAuthRoute: true,
};

const ResetEmail: RouteType = {
  path: "/resetpassword/email",
  name: "Send Reset Email",
  exact: true,
  icon: <i className="fa-solid fa-robot"></i>,
  component: ResetPassword,
  route: Route,
  isAuthRoute: true,
};

const ResetPin: RouteType = {
  path: "/resetpassword/pin",
  name: "Reset Pin",
  exact: true,
  icon: <i className="fa-solid fa-robot"></i>,
  component: EnterPin,
  route: Route,
  isAuthRoute: true,
};

const ResetPasswordFormRoute: RouteType = {
  path: "/resetpassword",
  name: "Reset Password",
  exact: true,
  icon: <i className="fa-solid fa-robot"></i>,
  component: ResetPasswordForm,
  route: Route,
  isAuthRoute: true,
};

const DashboardRoute: RouteType = {
  path: "/dashboard",
  name: "Dashboard",
  exact: true,
  icon: <i className="fa-solid fa-user"></i>,
  component: Dashboard,
  route: Route,
};

const RoadmapDisplayRoutes: RouteType = {
  path: "/roadmaps",
  name: "Roadmaps",
  exact: true,
  icon: <i className="fa-solid fa-road"></i>,
  component: YourRoadmaps,
  route: Route,
};

const MarketPlace: RouteType = {
  path: "/marketplace",
  name: "Roadmap Marketplace",
  exact: true,
  icon: <i className="fa-solid fa-store"></i>,
  component: Marketplace,
  route: Route,
};

const protectedRoutes = [DashboardRoute, RoadmapDisplayRoutes];

const authRoutes = [
  LogIn,
  SignUp,
  ResetEmail,
  ResetPin,
  ResetPasswordFormRoute,
];

const allRoutes = [...authRoutes, ...protectedRoutes];

export { allRoutes, authRoutes, protectedRoutes };
