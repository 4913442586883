import axios from "axios";

class CustomAxios {
  static configureGlobalDefaults() {
    axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URI;
    axios.defaults.withCredentials = true;

    axios.interceptors.response.use(undefined, (error) => {
      const pathName = window.location.pathname;
      if (error.response?.status) {
        //can implement some specifc error behavior if need be
      }

      return Promise.reject(error);
    });
  }

  static prefixedUrl(url: string) {
    return `${url}`;
  }

  static getModUrl(url: string) {
    let modUrl = CustomAxios.prefixedUrl(url);
    try {
      const userId = sessionStorage.getItem("userId");
      if (userId) {
        const fullUrl = `${axios.defaults.baseURL}${modUrl}`;
        const urlObj = new URL(fullUrl);
        const urlSearchParams = new URLSearchParams(urlObj.search);
        urlSearchParams.set("userId", userId);
        modUrl = urlObj.pathname + `?${urlSearchParams.toString()}`;
      }
    } catch (error) {
      console.log(error);
    }
    return modUrl;
  }

  static get(url: string, ...args: any[]) {
    return axios.get(CustomAxios.getModUrl(url), ...args);
  }

  static post(url: string, ...args: any[]) {
    return axios.post(CustomAxios.getModUrl(url), ...args);
  }

  static patch(url: string, ...args: any[]) {
    return axios.patch(CustomAxios.getModUrl(url), ...args);
  }

  static put(url: string, ...args: any[]) {
    return axios.put(CustomAxios.getModUrl(url), ...args);
  }

  static delete(url: string, ...args: any[]) {
    return axios.delete(CustomAxios.getModUrl(url), ...args);
  }
}

export default CustomAxios;
