import { useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

const useDebounce = (
  callback: (...args: any[]) => void,
  delay: number
): [
  (...args: any[]) => void,
  { loading: boolean; done: boolean; error: boolean }
] => {
  const handler = useRef<ReturnType<typeof setTimeout>>();
  const [state, setState] = useState({
    loading: false,
    done: false,
    error: false,
  });

  const toast = useToast();

  const debouncedFunction = (...args: any[]) => {
    if (handler.current) {
      clearTimeout(handler.current);
    }
    setState({ loading: true, done: false, error: false });
    handler.current = setTimeout(async () => {
      try {
        const res = await callback(...args);
        console.log(res);
        setState({ loading: false, done: true, error: false });
      } catch {
        setState({ loading: false, done: false, error: true });
        toast({
          title: "Edit Failed",
          description: "Unable to save. Please try again later.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    }, delay);
  };

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (handler.current) {
        clearTimeout(handler.current);
      }
    };
  }, []);

  return [debouncedFunction, state];
};

export default useDebounce;
