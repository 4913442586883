"use client";
/* eslint-disable */

// chakra imports
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Flex,
  HStack,
  Text,
  List,
  Icon,
  ListItem,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";

import { PropsWithChildren, useCallback } from "react";
import { RouteType } from "../../../../Core/Types";
import { useLocation, useNavigate } from "react-router-dom";
import NavLink from "../../link/NavLink";

interface SidebarLinksProps extends PropsWithChildren {
  routes: RouteType[];
}

export function SidebarLinks(props: SidebarLinksProps) {
  //   Chakra color mode
  const pathname = useLocation().pathname;
  let activeColor = useColorModeValue("navy.700", "white");
  let inactiveColor = useColorModeValue("gray.500", "gray.500");
  let borderColor = useColorModeValue("gray.200", "whiteAlpha.300");
  let activeIcon = useColorModeValue("brand.500", "white");
  let iconColor = useColorModeValue("navy.700", "white");
  let gray = useColorModeValue("gray.500", "gray.500");

  const navigate = useNavigate();

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = useCallback(
    (routeName: string) => {
      return pathname?.includes(routeName);
    },
    [pathname]
  );

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes: RouteType[]) => {
    return routes.map((route, key) => {
      if (!route.hidden) {
        return (
          <Accordion
            defaultIndex={0}
            allowToggle
            key={key}
            onClick={() => {
              navigate(route.path);
            }}
          >
            <Flex w="100%" justifyContent={"space-between"}>
              <AccordionItem border="none" key={key}>
                <AccordionButton
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  _hover={{
                    bg: "unset",
                  }}
                  _focus={{
                    boxShadow: "none",
                  }}
                  borderRadius="8px"
                  w="100%"
                  py="0px"
                  ms={0}
                  onClick={() => {
                    navigate(route.path);
                  }}
                >
                  {route.icon ? (
                    <Flex justifyContent="space-between" w="100%">
                      <HStack
                        spacing={
                          activeRoute(route.path.toLowerCase())
                            ? "22px"
                            : "26px"
                        }
                      >
                        <Flex
                          w="100%"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Box
                            color={
                              route.disabled
                                ? gray
                                : activeRoute(route.path.toLowerCase())
                                ? activeIcon
                                : inactiveColor
                            }
                            me="12px"
                            fontSize={"lg"}
                          >
                            {route.icon}
                          </Box>
                          <Text
                            me="auto"
                            color={
                              route.disabled
                                ? gray
                                : activeRoute(route.path.toLowerCase())
                                ? activeColor
                                : "gray.500"
                            }
                            fontWeight="700"
                            letterSpacing="0px"
                            fontSize="18px"
                            mt="14px"
                          >
                            {route.name}
                          </Text>
                        </Flex>
                      </HStack>
                    </Flex>
                  ) : (
                    <Flex pt="0px" pb="10px" alignItems="center" w="100%">
                      <HStack
                        spacing={
                          activeRoute(route.path.toLowerCase())
                            ? "22px"
                            : "26px"
                        }
                        ps="32px"
                      >
                        <Text
                          cursor="not-allowed"
                          me="auto"
                          fontWeight="500"
                          letterSpacing="0px"
                          fontSize="sm"
                        >
                          {route.name}
                        </Text>
                      </HStack>
                      <AccordionIcon
                        ms="auto"
                        color={route.disabled ? gray : "gray.500"}
                      />
                    </Flex>
                  )}
                </AccordionButton>
                {route.items && (
                  <AccordionPanel py="0px" ps={"8px"}>
                    <List>
                      {
                        route.icon && route.items
                          ? createLinks(route.items) // for bullet accordion links
                          : route.items
                          ? createAccordionLinks(route.items)
                          : "" // for non-bullet accordion links
                      }
                    </List>
                  </AccordionPanel>
                )}
              </AccordionItem>
            </Flex>
          </Accordion>
        );
      }
    });
  };
  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createAccordionLinks = (routes: RouteType[]) => {
    return routes.map((route: RouteType, key: number) => {
      return (
        <ListItem
          ms="28px"
          display="flex"
          alignItems="center"
          key={key}
          cursor="not-allowed"
        >
          <i>icon </i>
          <Text
            color={
              !route.disabled
                ? gray
                : activeRoute(route.path.toLowerCase())
                ? activeColor
                : inactiveColor
            }
            fontWeight={
              activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
            }
            fontSize="sm"
          >
            {route.name}
          </Text>
        </ListItem>
      );
    });
  };
  //  BRAND
  return <>{createLinks(routes)}</>;
}

export default SidebarLinks;
