import React, { useState, useContext } from "react";
import { useForm, FieldValues } from "react-hook-form";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { StoreContext } from "../Contexts/DataStore";
import { successToast } from "../Components/Misc/Toasts";

const ResetPasswordForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [generalError, setGeneralError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const email = location?.state?.email; // Get email passed from EnterPin
  const pin = location?.state?.pin;
  const store = useContext(StoreContext);

  const inputTextColor = useColorModeValue("gray.800", "gray.200");
  const boxCol = useColorModeValue("white", "gray.700");
  const pageBg = useColorModeValue(
    "linear(to-b, purple.100, gray.100 50%, gray.100)",
    "linear(to-b, purple.900, navy.900 60%, navy.900)"
  );
  const submitBg = useColorModeValue("purple.400", "purple.600");
  const password = watch("password");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const resetPassword = async (data: FieldValues) => {
    const result = await store.resetPassword({ ...data, pin, email });
    if (result?.status !== 200) {
      setGeneralError(
        result?.data?.message ?? "Something went wrong. Please try again."
      );
    } else {
      successToast("Password successfully changed.");
      navigate("/login"); // Redirect to login page after successful reset
    }
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bgGradient={pageBg}
      p={4}
    >
      <Stack
        spacing={4}
        mx={"auto"}
        maxW={"lg"}
        py={12}
        px={6}
        width={{ base: "90vw", md: "70vw", lg: "50vw" }}
      >
        <Stack align={"center"}>
          <Text
            fontSize={{ base: "2xl", md: "4xl" }}
            fontWeight={"bold"}
            mb="0"
          >
            Reset Password
          </Text>
        </Stack>
        <Box rounded={"lg"} bg={boxCol} boxShadow={"xl"} p={8}>
          <form onSubmit={handleSubmit(resetPassword)}>
            <Stack spacing={4}>
              <FormControl id="password">
                <FormLabel>New Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    color={inputTextColor}
                    {...register("password", { required: true, minLength: 6 })}
                  />
                  <InputRightElement h={"full"}>
                    <Button
                      variant={"ghost"}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                    >
                      {showPassword ? (
                        <i className="fa-solid fa-eye"></i>
                      ) : (
                        <i className="fa-solid fa-eye-slash"></i>
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {errors.password && (
                  <Text color="red.500" mb={0}>
                    Password is required and should be at least 6 characters.
                  </Text>
                )}
              </FormControl>
              <FormControl id="confirmPassword">
                <FormLabel>Confirm Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showConfirmPassword ? "text" : "password"}
                    color={inputTextColor}
                    {...register("confirmPassword", {
                      required: true,
                      validate: (value) =>
                        value === password || "Passwords do not match",
                    })}
                  />
                  <InputRightElement h={"full"}>
                    <Button
                      variant={"ghost"}
                      onClick={() =>
                        setShowConfirmPassword(
                          (showConfirmPassword) => !showConfirmPassword
                        )
                      }
                    >
                      {showConfirmPassword ? (
                        <i className="fa-solid fa-eye"></i>
                      ) : (
                        <i className="fa-solid fa-eye-slash"></i>
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {errors.confirmPassword && (
                  <Text color="red.500" mb={0}>
                    {(errors.confirmPassword?.message as string) ?? ""}{" "}
                  </Text>
                )}
              </FormControl>

              <Button
                type="submit"
                colorScheme="blue"
                width="full"
                bgColor={submitBg}
                color={"white"}
              >
                Reset Password
              </Button>
              {generalError && (
                <Text color="red.500" textAlign="center">
                  {generalError}
                </Text>
              )}
            </Stack>
            <Stack pt={2}>
              <Text align={"center"} onClick={() => navigate("/login")} mb={0}>
                <Link color={"blue.400"}>
                  <i className="fa-solid fa-arrow-left"></i> Back to Log in
                </Link>
              </Text>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export default ResetPasswordForm;
