"use client";
import "../Styles/tailwind.css";
import AppRoutes from "../Routes/AppRouter";
import "../Styles/App.css";
import "../Styles/Contact.css";
import "../Styles/Plugins.css";
import "../Styles/MiniCalendar.css";
import { Box, ChakraProvider } from "@chakra-ui/react";
import theme from "../theme/theme";
import { ReactFlowProvider } from "reactflow";

export default function App() {
  return (
    <html lang="en">
      <body id={"root"}>
        <ReactFlowProvider>
          <ChakraProvider theme={theme}>{<AppRoutes />}</ChakraProvider>
        </ReactFlowProvider>
      </body>
    </html>
  );
}
