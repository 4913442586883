import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Tooltip,
  Step,
  StepDescription,
  Kbd,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Box,
  Heading,
  HStack,
  Input,
  VStack,
  FormControl,
  FormLabel,
  Container,
  Badge,
  Checkbox,
  CheckboxGroup,
  Flex,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { HSeparator } from "../HorizonComponents/separator/Separator";
import { StoreContext } from "../../Contexts/DataStore";
import { GEN_LIMIT } from "../../Constants/MiscConstants";

const materials = ["YouTube Videos", "Websites", "Books", "Coursera Courses"];

export const NewRoadmapModal = ({ isOpen, onOpen, onClose }: any) => {
  const [steps, setSteps] = useState([
    { description: "Goals", canProceed: true },
    { description: "Materials", canProceed: false },
    { description: "Finish", canProceed: false },
  ]);
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const [name, setName] = useState<string>("");
  const [goal, setGoalState] = useState<string>("");
  const [selectedOptions, setSelectedOptions] = useState<any[]>(materials);

  const store = useContext(StoreContext);

  const toast = useToast();

  const nameRef = useRef<HTMLInputElement>(null);

  const setGoal = (goal: string) => {
    setGoalState(goal);

    const updatedSteps = steps.map((step, index) => {
      if (index === 1 || index === 2) {
        return { ...step, canProceed: goal !== "" };
      }
      return step;
    });
    setSteps(updatedSteps);
  };

  const setStep = (step: number) => {
    setActiveStep(step);
  };

  const closeAndReset = () => {
    onClose();
    setGoal("");
    setName("");
    setSelectedOptions(materials);
    setActiveStep(0);
  };

  const generateRoadmap = async () => {
    const data = {
      name,
      goal,
      materials: selectedOptions,
    };

    const roadmap = store.generateRoadmap(data);

    toast.promise(roadmap, {
      success: {
        title: "Roadmap Generation Complete",
        description: "Your roadmap is ready to be viewed!",
        position: "top-right",
      },
      error: {
        title: "Roadmap Generation Failed",
        description: "Something went wrong. Please try again later.",
        position: "top-right",
      },
      loading: {
        title: "Roadmap Generation in Progress",
        description: "This may take a few moments.",
        position: "top-right",
      },
    });

    closeAndReset();

    if (!roadmap) return;
  };

  const canGenerate = goal !== "" && name !== "";

  const handleKeyDown = (event: any) => {
    if (isDisabled) return;
    if (event.key === "Enter" && event.shiftKey) {
      if (activeStep > 0) {
        setStep(activeStep - 1);
      }
    } else if (event.key === "Enter") {
      if (!isOpen) {
        onOpen();
      } else if (activeStep === steps.length - 1 && canGenerate) {
        setStep(activeStep);
        generateRoadmap();
      } else if (
        activeStep < steps.length - 1 &&
        steps[activeStep + 1].canProceed
      ) {
        setStep(activeStep + 1);
      }
    } else if (event.key === "Escape") {
      closeAndReset();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeStep, isOpen, name, steps]);

  const getPage = (children: any) => {
    switch (activeStep) {
      case 0:
        return (
          <>
            <Container centerContent py={10}>
              <Heading as="h3" size="lg" mb={6} textAlign="center">
                What is your goal?
              </Heading>
              <div className="w-100">
                <VStack spacing={4}>
                  <FormControl id="goal">
                    <Input
                      type="text"
                      placeholder="I want to become an expert videographer..."
                      value={goal}
                      onChange={(e) => setGoal(e.target.value)}
                      bg="white"
                    />
                  </FormControl>
                  {children}
                </VStack>
              </div>
            </Container>
          </>
        );
      case 1:
        return (
          <>
            <Container centerContent py={10}>
              <Heading as="h4" size="lg" mb={6} textAlign="center">
                What materials would you like to use?
              </Heading>
              <div>
                <VStack spacing={4} justify="center">
                  <FormControl id="goal">
                    <CheckboxGroup
                      colorScheme="blue"
                      value={selectedOptions}
                      onChange={(value) => setSelectedOptions(value as never[])}
                    >
                      <HStack wrap="wrap" spacing={4}>
                        {materials.map((material) => (
                          <Checkbox value={material}>
                            <Badge
                              colorScheme="blue"
                              px={4}
                              py={2}
                              borderRadius="full"
                            >
                              {material}
                            </Badge>
                          </Checkbox>
                        ))}
                      </HStack>
                    </CheckboxGroup>
                  </FormControl>

                  {children}
                </VStack>
              </div>
            </Container>
          </>
        );
      case 2:
        return (
          <>
            <Container centerContent py={10}>
              <Heading as="h4" size="lg" mb={6} textAlign="center">
                What is the name of your roadmap?
              </Heading>
              <div className="w-100">
                <VStack spacing={4}>
                  <FormControl id="goal">
                    <Input
                      ref={(inputElement) => {
                        if (inputElement) {
                          inputElement.focus();
                        }
                        return nameRef;
                      }}
                      type="text"
                      placeholder="Enter the name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      bg="white"
                    />
                  </FormControl>

                  {children}
                </VStack>
              </div>
            </Container>
          </>
        );
    }
  };

  const inputColor = useColorModeValue("navy.700", "white");
  const isDisabled =
    (store.user?.totalGenerations === undefined ||
      store.user?.totalGenerations > GEN_LIMIT) &&
    store.user?.role !== "Developer";
  return (
    <>
      <Tooltip
        label="You have no more generations left."
        isDisabled={!isDisabled}
        placement="top"
      >
        <Button
          size="md"
          colorScheme={!isDisabled ? "purple" : "grey"}
          className="mr-2 w-2/5"
          onClick={() => !isDisabled && onOpen()}
          borderRadius="md"
          variant="outline"
          disabled={isDisabled}
        >
          <Box display={{ base: "none", md: "inline" }}>Create New Roadmap</Box>
          <Box display={{ base: "inline", md: "none" }}>New</Box>
          <i className="fa-solid fa-plus ml-2"></i>
        </Button>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={closeAndReset} size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Stepper size="sm" index={activeStep}>
              {steps.map((step, index) => (
                <Step
                  key={index}
                  onClick={
                    step.canProceed ? () => setActiveStep(index) : undefined
                  }
                  style={{
                    pointerEvents: step.canProceed ? "auto" : "none",
                    opacity: step.canProceed ? 1 : 0.5,
                  }}
                >
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <StepTitle className="mt-2">{step.description}</StepTitle>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
          </ModalHeader>
          <HSeparator />
          <ModalBody>
            {getPage(
              <>
                {activeStep < steps.length - 1 ? (
                  <Button
                    colorScheme="blue"
                    size="md"
                    width="full"
                    bgGradient="linear(to-r, blue.400, purple.500 80%)"
                    position="relative"
                    overflow="hidden"
                    _before={{
                      position: "absolute",
                      content: `""`,
                      width: "100%",
                      height: "100%",
                      bgGradient: "linear(to-r, purple.500, blue.400 80%)",
                      transition: "opacity .7s ease",
                    }}
                    _hover={{
                      _before: { opacity: 0 },
                    }}
                    onClick={() => {
                      setStep(activeStep + 1);
                    }}
                  >
                    <Box position="relative" zIndex={1}>
                      Next
                    </Box>
                  </Button>
                ) : (
                  <Button
                    colorScheme="blue"
                    size="md"
                    width="full"
                    bgGradient="linear(to-r, blue.400, purple.500 80%)"
                    position="relative"
                    overflow="hidden"
                    _before={{
                      position: "absolute",
                      content: `""`,
                      width: "100%",
                      height: "100%",
                      bgGradient: "linear(to-r, purple.500, blue.400 80%)",
                      transition: "opacity .7s ease",
                    }}
                    _hover={{
                      _before: { opacity: 0 },
                    }}
                    onClick={() => canGenerate && generateRoadmap()}
                  >
                    <Box position="relative" zIndex={1}>
                      Generate
                    </Box>
                  </Button>
                )}
              </>
            )}
          </ModalBody>
          <HSeparator />

          <ModalFooter>
            <>
              <Flex width="100%" justify="space-between">
                {activeStep > 0 ? (
                  <Button
                    variant="ghost"
                    onClick={() => {
                      setStep(activeStep - 1);
                    }}
                  >
                    <span className="font-bold" color={inputColor}>
                      Back{" "}
                    </span>{" "}
                    <Kbd className="ml-2 mr-1" color={inputColor}>
                      Shift
                    </Kbd>{" "}
                    +
                    <Kbd className="ml-1" color={inputColor}>
                      Enter
                    </Kbd>
                  </Button>
                ) : (
                  <div />
                )}
                {activeStep < steps.length - 1 ? (
                  <Button
                    variant="ghost"
                    size="md"
                    onClick={() => {
                      setStep(activeStep + 1);
                    }}
                  >
                    <span className="font-bold">Next </span>{" "}
                    <Kbd color={inputColor} className="ml-2">
                      Enter
                    </Kbd>
                  </Button>
                ) : (
                  <Button variant="ghost" onClick={() => {}}>
                    <span className="font-bold">Finish </span>{" "}
                    <Kbd color={inputColor} className="ml-2">
                      Enter
                    </Kbd>
                  </Button>
                )}{" "}
              </Flex>
            </>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
